export function validateCPF(cpf) {
  cpf = cpf.replace(/\./g, '');
  cpf = cpf.replace(/\-/g, '');
  let Soma;
  let Resto;
  Soma = 0;
  if (cpf.toString() === '00000000000') return false;
  if (cpf.toString() === '11111111111') return false;
  if (cpf.toString() === '22222222222') return false;
  if (cpf.toString() === '33333333333') return false;
  if (cpf.toString() === '44444444444') return false;
  if (cpf.toString() === '55555555555') return false;
  if (cpf.toString() === '66666666666') return false;
  if (cpf.toString() === '77777777777') return false;
  if (cpf.toString() === '88888888888') return false;
  if (cpf.toString() === '99999999999') return false;

  for (let i = 1; i <= 9; i++) Soma += parseInt(cpf.substring(i - 1, i)) * (11 - i);
  Resto = (Soma * 10) % 11;

  if ((Resto === 10) || (Resto === 11)) Resto = 0;
  if (Resto !== parseInt(cpf.substring(9, 10))) return false;

  Soma = 0;
  for (let i = 1; i <= 10; i++) Soma += parseInt(cpf.substring(i - 1, i)) * (12 - i);
  Resto = (Soma * 10) % 11;

  if ((Resto === 10) || (Resto === 11)) Resto = 0;
  if (Resto !== parseInt(cpf.substring(10, 11))) return false;
  return true;
}

import feedbackService from "../services/feedbackService";
import api from "./api";

const attachTokenToRequest = async (config) => {
  const user = await JSON.parse(window.localStorage.getItem("persist:user"));

  if (user && config.baseURL === process.env.REACT_APP_API_URL) {
    config.headers["User-Token"] = JSON.parse(user.token);
  }
  return config;
};

api.interceptors.request.use(
  async (config) => await attachTokenToRequest(config),
  (error) => Promise.reject(error)
);

api.interceptors.response.use(
  (response) => response,
  (error) => {
    const { response = {} } = error;
    const { status = null, data = null } = response;

    try {
      if (status === -1 || status === 0 || status === 401) {
        // feedbackService.showErrorMessage("Erro ao acessar a requisição.");
      } else if (status === 403) {
        // feedbackService.showErrorMessage("Erro ao acessar a requisição.");
      } else if (status === 405) {
        feedbackService.showErrorMessage(
          "A requisição especificada não é permitida ou está errada."
        );
      } else if (status === 409) {
        feedbackService.showErrorMessage(data.message);
      } else if (status === 451) {
        window.localStorage.clear();
        return Promise.reject(error.response);
      } else if (status === 502) {
        feedbackService.showErrorMessage(
          "Ops! Parece que nosso servidor está fora! Tente novamente em alguns momentos."
        );
      } else if (status >= 500 && status <= 599) {
        feedbackService.showErrorMessage(
          "Ops! Parece que algo deu errado com sua requisição. Já enviamos uma notificação para nossa equipe de desenvolvimento."
        );
      }
    } catch (error) {
      return Promise.reject(error);
    }
    return Promise.reject(error);
  }
);

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  get(url, options = {}) {
    return api.get(url, { ...options });
  },

  getpost(url, data, options = {}) {
    return api.get(url, data, { ...options });
  },

  post(url, data, options = {}) {
    return api.post(url, data, { ...options });
  },

  put(url, data, options = {}) {
    return api.put(url, data, { ...options });
  },

  delete(url, options = {}) {
    return api.delete(url, { ...options });
  },
};

import React, { useEffect, useState } from "react";
import _ from "lodash";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { Container } from "@mui/material";
import { fetchOpenVipList, fetchVipList } from "../../providers/providers";
import VipListCardFull from "../../components/viplist/VipListCardFull";
import BlurEventInfo from "../../components/event/BlurEventInfo";
import LoginModal from "../login/components/LoginModal";
import SignupModal from "../signup/components/SignupModal";
import feedbackService from "../../services/feedbackService";

function VipListDetail() {
  const navigate = useNavigate();
  const params = useParams();
  const location = useLocation();
  const { state } = location;
  const open = state?.open;
  const user = useSelector(({ auth }) => auth.user);
  const { listaId } = params;

  const [list, setList] = useState(null);
  const [blurBackground, setBlurBackground] = useState(false);
  const [exclusive, setExclusive] = useState(false);
  const [login, setLogin] = useState(false);
  const [loading, setLoading] = useState(true);
  const [signup, setSignup] = useState(false);

  const debouncedGetEvent = _.debounce(getList, 300);

  useEffect(() => {
    debouncedGetEvent();

    return () => {
      debouncedGetEvent.cancel();
    };
  }, [params]);

  function getList() {
    if (open) {
      fetchOpenVipList(listaId)
        .then((res) => {
          setList(res.data);
        })
        .catch((err) => {
          if (err.response.status === 403 || err.response.status === 401) {
            navigate(`/`);
          }
        })
        .finally(() => setLoading(false));
    } else {
      fetchVipList(listaId)
        .then((res) => {
          setList(res.data);
          setBlurBackground(false);
          setLogin(false);
          setSignup(false);
        })
        .catch((err) => {
          setBlurBackground(true);
          if (user && err.response.status === 403) {
            navigate(`/`);
            feedbackService.showInfoMessage(
              "Lista Vip exclusiva! Você não tem permissão para acessar essa lista."
            );
          } else if (!user && err.response.status === 403) {
            setExclusive(true);
            setLogin(true);
          } else if (!user && err.response.status === 401) {
            setLogin(true);
          }
        })
        .finally(() => setLoading(false));
    }
  }

  function handleSignupModal() {
    setLogin(false);
    setSignup(true);
  }

  function handleLoginModal() {
    setSignup(false);
    setLogin(true);
  }

  return (
    <Container sx={{ mt: 2, mb: 2, minHeight: 400 }} maxWidth="md">
      {(loading || blurBackground) && <BlurEventInfo />}

      <LoginModal
        open={login}
        returnNav={`/lista-vip/${listaId}`}
        handleSignupModal={handleSignupModal}
        title={exclusive ? "Essa é uma lista exclusiva!" : "Login"}
        subtitle={
          exclusive
            ? "Caso tenha recebido um email convite efetue login ou cadastro para acessar."
            : "Para acessar, é necessário efetuar o login."
        }
      />

      <SignupModal
        open={signup}
        returnNav={`/lista-vip/${listaId}`}
        handleLoginModal={handleLoginModal}
      />

      {list ? (
        <VipListCardFull
          item={list}
          listaId={listaId}
          callbackAction={() => getList()}
        />
      ) : null}
    </Container>
  );
}

export default VipListDetail;

import React, { useState, useEffect } from "react";
import { CircularProgress, Fade, Zoom } from "@mui/material";
import { Box, Typography, FormControlLabel, Checkbox } from "@mui/material";
import { Form, Button, Row } from "react-bootstrap";
import Modal from "@mui/material/Modal";
import feedbackService from "../../services/feedbackService";
import { postEnterOpenVipList } from "../../providers/viplistProvider";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { EnterVipListSuccessfull } from "./components/EnterVipListSuccessfull";
import { NameAlreadyInList } from "./components/NameAlreadyInList";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  minWidth: "350px",
  maxWidth: "400px",
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: 5,
  p: 4,
};

const formDefault = {
  email: "",
  name: "",
};

export function EnterOpenVipListModal({
  list,
  open = false,
  callback,
  onCancel,
}) {
  const [form, setForm] = useState(formDefault);
  const [loading, setLoading] = useState(false);
  const [segmentationAccepted, setSegmentationAccepted] = useState(false);
  const [success, setSuccess] = useState(false);
  const [nameAlreadyInList, setNameAlreadyInList] = useState(false);

  function isValidated() {
    return form.email !== "" && form.name !== "" && segmentationAccepted;
  }

  function submit(ev) {
    ev.preventDefault();
    setLoading(true);

    postEnterOpenVipList(list.id, { ...form })
      .then((res) => {
        setSuccess(true);
      })
      .catch((err) => {
        console.log(err.response);
        const errorData = err.response.data;

        if (
          errorData.error_code &&
          errorData.error_code === "name_already_in_list"
        ) {
          setNameAlreadyInList(true);
        } else {
          feedbackService.showErrorMessage(
            `${errorData.msg}. Se o problema persistir, entre em contato via @makemynight.`
          );
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }

  function handleClose() {
    callback();
    setSegmentationAccepted(false);
    setForm(formDefault);
    setSuccess(false);
    setNameAlreadyInList(false);
  }

  return (
    <Modal
      open={open}
      onClose={onCancel}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        {success ? (
          <EnterVipListSuccessfull
            success={success}
            handleClose={handleClose}
            list={list}
          />
        ) : nameAlreadyInList ? (
          <NameAlreadyInList
            show={nameAlreadyInList}
            handleClose={handleClose}
            list={list}
          />
        ) : (
          <>
            <Typography
              id="modal-modal-title"
              variant="h6"
              component="h2"
              fontWeight={900}
              textAlign={"center"}
            >
              {list.title}
            </Typography>
            <Typography
              id="modal-modal-title"
              variant="h6"
              component="h6"
              fontWeight={900}
              textAlign={"center"}
            >
              Lista {list.segmentation}
            </Typography>
            <Typography
              id="modal-modal-title"
              variant="body"
              component="h6"
              marginBottom={5}
              textAlign={"center"}
            >
              Insira os dados para entrar na lista
            </Typography>
            <Form onSubmit={submit}>
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label size="sm">Nome</Form.Label>
                <Form.Control
                  value={form.name}
                  onChange={(ev) => setForm({ ...form, name: ev.target.value })}
                  name="name"
                  type="name"
                  size="sm"
                />
                <Form.Text className="text-muted" />
              </Form.Group>

              <Form.Group className="mb-4" controlId="formBasicEmail">
                <Form.Label size="sm">Email</Form.Label>
                <Form.Control
                  value={form.email}
                  onChange={(ev) =>
                    setForm({ ...form, email: ev.target.value })
                  }
                  name="email"
                  type="email"
                  size="sm"
                />
                <Form.Text className="text-muted" />
              </Form.Group>

              <span>
                Por favor, confirme que você está ciente de que está
                selecionando uma <strong>lista {list.segmentation}</strong>.
              </span>
              <FormControlLabel
                className="d-block"
                control={
                  <Checkbox
                    checked={segmentationAccepted}
                    onClick={() => setSegmentationAccepted((prev) => !prev)}
                  />
                }
                label="Eu confirmo e desejo participar."
              />

              <Row className="justify-content-center mx-3 mt-5">
                <Button
                  disabled={!isValidated()}
                  size="sm"
                  variant="primary"
                  type="submit"
                  style={{ fontWeight: 600 }}
                >
                  {loading ? (
                    <CircularProgress size={20} color="secondary" />
                  ) : (
                    "CONFIRMAR"
                  )}
                </Button>
              </Row>
            </Form>
          </>
        )}
      </Box>
    </Modal>
  );
}

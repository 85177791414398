import React from 'react';
import { Card, Spinner } from 'react-bootstrap';

function Loading({minHeight = 400}) {
  return (
    <Card style={{ flex: 1, minHeight }} className="mt-3 mb-3 custom-content-spinner">
      <Card.Body className="d-flex align-items-center justify-content-center">
        <Spinner animation="grow" className="m-3" color='primary'/>
        <Spinner animation="grow" className="m-3" color='primary' />
        <Spinner animation="grow" className="m-3" color='primary' />
      </Card.Body>
    </Card>
  );
}

export default Loading;

import React from 'react';
import { Container } from 'react-bootstrap';

function ImageTerms() {
  return (
    <div className="home-container">
      <Container>
        <div className="my-5">
          <ul>
            <h4 className="my-5"><strong>POLÍTICA DE USO DE IMAGEM</strong></h4>
            <li>
              <p>
                <span style={{ fontWeight: 400 }}>
                  Consinto que o organizador oficial deste evento e a plataforma do Makemynight, através da cobertura fotográfica da festa, possa usar a minha imagem, direta ou indiretamente, nas publicidades veiculadas em redes sociais, em jornais impressos ou online, em programas televisivos, em eventos festivos ou patrocinados e em quaisquer outros meios de comunicação ou eventos,
                  de acordo com o
                  <strong>art. 20 do Código Civil e art. 7º, I, da Lei n. º 13.709/18 (Lei Geral de Proteção de Dados). </strong>
                </span>
              </p>
            </li>
            <li>
              <p>
                <span style={{ fontWeight: 400 }}>
                  Usuário
                  <strong>DECLARANTE</strong>
                  {' '}
                  aceita o presente termo de consentimento de forma livre, consciente e voluntária.
                  {' '}
                </span>
              </p>
            </li>
            <li>
              <p>
                <span style={{ fontWeight: 400 }}>
                  O Makemynight e qualquer estabelecimento parceiro que atue em nossa plataforma
                  <strong>NÃO</strong>
                  {' '}
                  usarão a imagem do
                  <strong>DECLARANTE</strong>
                  {' '}
                  para outra finalidade diversa da constante neste termo de consentimento.
                  {' '}
                </span>
              </p>
            </li>
          </ul>
        </div>
      </Container>
    </div>
  );
}

export default ImageTerms;

import { createSlice } from "@reduxjs/toolkit";

export const authReducer = createSlice({
  name: "auth",
  initialState: {
    user: null,
    token: null,
    state: null,
  },
  reducers: {
    setUserData: (state, action) => {
      state.user = action.payload;
    },
    setToken: (state, action) => {
      state.token = action.payload;
    },
    setUserState: (state, action) => {
      state.state = action.payload;
    },
    logout: (state, action) => {
      state.user = null;
      state.token = null;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setUserData, setToken, setUserState, logout } =
  authReducer.actions;

export default authReducer.reducer;

import React from "react";
import { useDispatch, useSelector } from "react-redux";
import Modal from "@mui/material/Modal";
import { close } from "../../store/root/dialog/dialog.reducer";

function ModalDialog() {
  const dispatch = useDispatch();
  const open = useSelector(({ root }) => root.dialog.open);
  const options = useSelector(({ root }) => root.dialog.options);

  function handleClose() {
    dispatch(close());
  }
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      {...options}
    >
      {options.children}
    </Modal>
  );
}

export default ModalDialog;

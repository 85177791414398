import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Breadcrumb } from 'react-bootstrap';
import QRCode from 'react-qr-code';
import  {Paper, Grid, Typography, Box, Container, TextField, CircularProgress,  Button, Card, CardContent, Chip} from '@mui/material';
import { fetchMyListInscriptions, fetchSendVipListInvite } from '../../../providers/viplistProvider';
import Loading from "../../../components/loading/Loading";
import feedbackService from '../../../services/feedbackService';

function VipListInvitations(){
    const params = useParams();
    const navigate = useNavigate();
    const {listaId} = params;

    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        getData();
    }, []);
    
    function getData() {
        fetchMyListInscriptions().then((res) => {
          let itemData = res.data.filter((item) => item.guest === false && item.list.id === parseInt(listaId)).map((item) => {
            item.invites = res.data.filter((invitation) => invitation.guest === true && invitation.list.id === item.list.id).map((invitation) => invitation)
            return item
          })
          setData(itemData[0])
        }).catch((err) => console.log(err));
    }

    function handleSend(invite){
        setLoading(true);
        fetchSendVipListInvite(invite.list.id, invite.id, {name: invite.name, email: invite.email}).then((res) => {
            setLoading(false);
            feedbackService.showSuccessMessage("Convite enviado com sucesso!")
        }).catch((err) => {
            setLoading(false);
            feedbackService.showErrorMessage("Ops! Houve um problema ao enviar convite. Tentar novamente ou entre em contato com @makemynight")
        })
    }
    const handleChangeInvite = (ev, invite) => {
        let invites = data.invites.map((_invite) => {
            if (_invite.id === invite.id) {
                return {..._invite, [ev.target.name]: ev.target.value}
            }
            return _invite
        })
        setData({...data, invites: invites})
    };

    return (
        <Container sx={{mt: 2, mb: 2}} maxWidth="md">
            <Paper>
                <Box sx={{p:2, mt:2, mb: 5}}>
                    <Breadcrumb>
                        <Breadcrumb.Item onClick={() => navigate('/my-lists')}>Minhas listas</Breadcrumb.Item>
                        <Breadcrumb.Item active>
                            Convites {data && data.list.event.name}
                        </Breadcrumb.Item>
                    </Breadcrumb>                             
                    <Typography variant='h5' fontWeight={600}>Convites</Typography>
                    {data && <Typography variant='subtitle1'>{data.invites.length} convites disponíveis</Typography>}
                </Box>
            </Paper>            
            {
                data ? (
                    <>                                       
                    <Grid container  spacing={2}>
                        {data.invites.map((invite) => {
                            return (
                                <Grid item xs={12} md={4}>
                                    <Card>
                                        <div className='d-flex justify-content-center'>
                                            <QRCode value={invite.code} /> 
                                        </div>
                                        <CardContent>
                                            <Typography noWrap gutterBottom variant="h6" component="div" textAlign='center'>
                                                Convite #{invite.id}
                                            </Typography>
                                            <Typography noWrap gutterBottom variant="h6" component="div" textAlign='center'>
                                                <Chip label={invite.presence_confirmed ? 'Confirmado' : 'Não confirmado'}></Chip>
                                            </Typography>                                            
                                            <Box sx={{mt:2, mb:2}}>
                                                <TextField 
                                                    label="Nome"
                                                    name="name"
                                                    value={invite.name}
                                                    onChange={(ev) => handleChangeInvite(ev, invite)}
                                                    fullWidth
                                                    className="mb-2"
                                                />
                                                <TextField 
                                                    name="email"
                                                    label="E-mail"
                                                    value={invite.email}
                                                    onChange={(ev) => handleChangeInvite(ev, invite)}
                                                    fullWidth
                                                    className="mb-2"
                                                />  
                                            </Box>   
                                            <div className="d-flex justify-content-end">
                                                <Button 
                                                    disabled={invite.name === '' || invite.email === ''}
                                                    onClick={() => handleSend(invite)}>
                                                    {loading ? (<CircularProgress size={20} color='secondary'/> ): ('Enviar')}                         
                                                </Button>
                                            </div>                                                                           
                                        </CardContent>
                                    </Card>
                                </Grid>
                            )
                        })}
                        </Grid>
                    </>
                ) : (
                    <Loading minHeight={800} />
                )
            }         
        </Container>
    )
}
export default VipListInvitations
import React from 'react';
import { Form, Image } from 'react-bootstrap';
import { Upload } from 'react-bootstrap-icons';
import './avatar.css';

function CustomInputFile({
  id, label = '', img, callback, rounded = false, width = 120, height = 120,
}) {
  function onchangeimg(e) {
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      callback(reader.result);
    };
    reader.onerror = function (error) {

    };
  }
  return (
    <div>
      <Form.Label>
        {label}
      </Form.Label>
      <Form.Label className="custom-input-file-label text-center" for={id}>
        <Image
          style={{
            width, height, objectFit: 'cover', marginBottom: 12,
          }}
          src={img}
          roundedCircle={rounded}
        />
        <Upload size={24} />
      </Form.Label>
      <Form.Control className="custom-input-file" id={id} type="file" onChange={onchangeimg} />
    </div>
  );
}

export default CustomInputFile;

import React, { useEffect, useState } from 'react';
import  { Container, Grid, Typography, Paper, Box, Tabs, Tab } from '@mui/material';
import * as _ from 'lodash';
import moment from "moment";
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { fetchMyListInscriptions } from '../../../providers/viplistProvider';
import VipListCardItem from '../../../components/viplist/VipListCardItem';
import VipListUserCardActions from '../../../components/viplist/VipListUserCardActions';
import { VipListQRCodeModal } from '../../../components/viplist/VipListQRCodeModal';

function UserVipLists() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = useSelector(({ auth }) => auth.user);
  const [data, setData] = useState([]);
  const [previous, setPrevious] = useState([]);

  const [value, setValue] = useState(0);

  useEffect(() => {
    if (user) {
        getData();
    }
  }, [user]);

  function getData() {
    fetchMyListInscriptions().then((res) => {
      let next = res.data.filter((item) => moment(item.list.event.date) >= moment())
      next = next.filter((item) => item.guest === false).map((item) => {
        item.invites = next.filter((invitation) => invitation.guest === true && invitation.list.id === item.list.id).map((invitation) => invitation)
        return item
      })
      setData(next);

      let previous = res.data.filter((item) => moment(item.list.event.date) < moment())
      previous = previous.filter((item) => item.guest === false).map((item) => {
        item.invites = previous.filter((invitation) => invitation.guest === true && invitation.list.id === item.list.id).map((invitation) => invitation)
        return item
      })      
      setPrevious(previous);

    }).catch((err) => console.log(err));
  }

  const navTopInvitationsPage = (item) => {
    navigate(`/my-lists/${item.id}/invitations`);
  };

  const navToListDetail = (item) => {
    navigate(`/list-vip/${item.id}`);
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleOpenQRCode = (item) => {
    VipListQRCodeModal({
      dispatch,
      code: item.code,
    });
  };

  const ListItem = ({item}) => {
    return (
        <Grid item key={uuidv4()} md={6} sm={12} xs={12} className="mb-3">
            <VipListCardItem 
              item={item} 
              callback={() => navToListDetail(item)}
              userActionsComponent={
                <VipListUserCardActions
                  callbackInvites={() => navTopInvitationsPage(item)}
                  callbackQRCode={() => handleOpenQRCode(item)}
                />
              }
            />
        </Grid>
    );
  };

  return (
    <Container sx={{mt: 2, mb: 2}} maxWidth="md">
      <Paper>
          <Box sx={{p:2, mt:2, mb: 2}}>
              <Typography variant='h5'>Minhas listas</Typography>
          </Box>
      </Paper>
      <Tabs value={value} onChange={handleChange} sx={{mb: 2}}>
          <Tab label="Ativos"/>
          <Tab label="Encerrados"/>
      </Tabs>   
      <Box sx={{minHeight: 600}}>
        <Grid container spacing={2}>
        {
            value === 0 ? (
              data.map((item) => <ListItem item={item.list} />)
            ) : (
              previous.map((item) => <ListItem item={item.list} />)
            )
        }               
        </Grid>         
      </Box>       
    </Container>  
  );
}

export default UserVipLists;

import React from "react";
import { Fade, Zoom } from "@mui/material";
import { Box, Typography } from "@mui/material";
import { Button } from "react-bootstrap";

import InfoIcon from "@mui/icons-material/Info";

export function NameAlreadyInList({ list, show, handleClose }) {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <Zoom
        in={show}
        style={{ transformOrigin: "0 0 0" }}
        {...(show ? { timeout: 1000 } : {})}
      >
        <InfoIcon style={{ fontSize: 100 }} color="primary" />
      </Zoom>
      <Fade
        in={show}
        style={{ transformOrigin: "0 0 0" }}
        {...(show ? { timeout: 2000 } : {})}
      >
        <Typography
          variant="h6"
          fontWeight={900}
          textAlign={"center"}
          marginTop={2}
        >
          Atenção!
        </Typography>
      </Fade>
      <Fade
        in={show}
        style={{ transformOrigin: "0 0 0" }}
        {...(show ? { timeout: 2000 } : {})}
      >
        <Typography variant="caption" fontSize={14} textAlign={"center"}>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <span>Seu nome já foi adicionado na lista {list.segmentation}</span>
            <span>{list.title} anteriormente.</span>
          </div>
        </Typography>
      </Fade>
      <Fade
        in={show}
        style={{ transformOrigin: "0 0 0" }}
        {...(show ? { timeout: 2500 } : {})}
      >
        <Button
          size="sm"
          variant="primary"
          style={{ fontWeight: 900, marginTop: 22 }}
          onClick={handleClose}
        >
          OK
        </Button>
      </Fade>
    </Box>
  );
}

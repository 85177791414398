import React from 'react';
import './banner.css';

function Banner({image}) {
  return (
    <div className="banner-content">
      <img src={image} className="img-fluid banner" alt="Banner Make My Night" />
    </div>
  );
}

export default Banner;

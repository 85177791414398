import httpClientMethod from "./httpClientMethod";

export function fetchVipLists(page = 1, state = "") {
  return httpClientMethod.get(
    `/web_lists.json?state_code=${state}&page=${page}&per_page=100`
  );
}

export function fetchVipList(id) {
  return httpClientMethod.get(`/lists/${id}.json`);
}

export function fetchOpenVipList(id) {
  return httpClientMethod.get(`/open_lists/${id}.json`);
}

export function postEnterOpenVipList(id, data) {
  return httpClientMethod.post(`/open_lists/${id}.json`, data);
}

export function fetchEnterVipList(id) {
  return httpClientMethod.post(`/lists/${id}/facebook.json`);
}

export function fetchExitVipList(id) {
  return httpClientMethod.delete(`/lists/${id}/exit_list.json`);
}

export function fetchMyListInscriptions() {
  return httpClientMethod.get(`/api/v2/lists/invitations`);
}

export function fetchSendVipListInvite(listId, invitationId, payload) {
  return httpClientMethod.put(
    `/lists/${listId}/invitations/${invitationId}`,
    payload
  );
}

import { combineReducers } from "redux";
import dialog from "./dialog/dialog.reducer";
import stateDialog from "./dialog/state-dialog.reducer";
import pageLoading from "./pageLoading.reducer";

const rootReducers = combineReducers({
  dialog,
  stateDialog,
  pageLoading
});

export default rootReducers;

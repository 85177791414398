import React, { useEffect, useState } from "react";
import moment from "moment";
import { v4 as uuid } from "uuid";
import {
  Card,
  Row,
  Col,
  Button,
  Form,
  Image,
  Badge,
  InputGroup,
  ListGroup,
  FormControl,
} from "react-bootstrap";
import { Container } from "@mui/material";
import { useParams, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { ArrowRight, Check2Circle } from "react-bootstrap-icons";
import MaskedInputField from "react-maskedinput";
import { useMercadopago } from "react-sdk-mercadopago";

import PixIcon from "@mui/icons-material/Pix";
import CreditCardIcon from "@mui/icons-material/CreditCard";

import * as moneyUtil from "../../application/util/moneyUtil";
// imagens
import defaultUser from "../../assets/imgs/default-user.png";
import ssl from "../../assets/imgs/ssl.webp";

import feedbackService from "../../services/feedbackService";
import paymentService from "../../services/paymentService";
import adService from "../../services/adService";

import { AlertConfirm } from "../../components/modal/AlertConfirm";
import { AlertInfo } from "../../components/modal/AlertInfo";
import * as eventProvider from "../../providers/eventProvider";
import * as generalProvider from "../../providers/generalProvider";
import * as configs from "../../providers/configs";
import Coupon from "./components/Coupon";
import PurchaseResume from "./components/PurchaseResume";
import Loading from "../../components/loading/Loading";

import "./checkout.css";
import checkoutService from "./checkoutService";

const formDefault = {
  accept_terms: false,
  name: "",
  email: "",
  docType: "CPF",
  docNumber: "",
  phone: "",
  cardNumber: "",
  cardholderName: "",
  securityCode: "",
  cardExpiration: "",
  cardExpirationMonth: "",
  cardExpirationYear: "",
  tickets: [],
  amount: 0,
  rate: 0,
  rate_pix: 0,
  amount_pix: 0,
  //coupon
  coupon_code: "",
  coupon_discount: 0,
  //
  installments: 1,
  street: "",
  number: "",
  neighborhood: "",
  cep: "",
  city: "",
  complement: "",
  state: "",

  payer_costs: [],
};

function Checkout() {
  const mp = useMercadopago.v2(configs.MERCADOPAGO);

  const params = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const user = useSelector(({ auth }) => auth.user);
  const [event, setEvent] = useState(null);
  const [form, setForm] = useState(formDefault);
  const [step, setStep] = useState(1);
  const [tickets, setTicket] = useState([]);
  const [loading, setLoading] = useState(false);
  const [paymentForm, setPaymentForm] = useState("");
  const [pix, setPix] = useState("");
  const [paid, setPaid] = useState(false);

  const formatMoney = moneyUtil.formatMoney;

  const handleChange = (field, newValue) => {
    setForm({ ...form, [field]: newValue });
  };

  useEffect(() => {
    getEvent();
  }, [params]);

  function getEvent() {
    eventProvider
      .fetchEvent(params.eventId)
      .then((res) => {
        setEvent(res.data);
      })
      .catch((err) => console.log(err));
  }

  function generateArray(number, minimum = 1) {
    let n = number > 0 ? number : 0;
    return Array.from(Array(n).keys(), (x) => x + minimum);
  }
  function trackPix() {
    adService.trackFbCustom("GeneratedPix", {
      currency: "BRL",
      value: form.amount_pix,
    });
    adService.trackGa({
      category: "Checkout",
      action: "Gerar pix",
      label: "Pagar",
    });
    if (event.pixel) {
      adService.trackSingleCustom(event.pixel, "GeneratedPix", {
        currency: "BRL",
        value: form.amount_pix,
      });
    }
  }

  function trackCreditCrad() {
    adService.trackFb("Purchase", {
      currency: "BRL",
      value: form.amount,
    });
    adService.trackGa({
      category: "Checkout",
      action: "Finalizar compra",
      label: "Pagar",
    });
    if (event.pixel) {
      adService.trackSingle(event.pixel, "Purchase", {
        currency: "BRL",
        value: form.amount,
      });
    }
  }

  async function generateToken(gateway) {
    if (gateway === "pg") {
      const cardExpirationMonth = form.cardExpiration.split("/")[0];
      const cardExpirationYear = form.cardExpiration.split("/")[1].slice(-2);
      const cardTokenPayloadPg = {
        card: {
          billing_address: {
            zip_code: form.cep.toString(),
            city: form.city,
            state: form.state,
            country: "BR",
            line_1: `${form.number},${form.street},${form.neighborhood}`,
          },
          number: form.cardNumber,
          holder_name: form.cardholderName.replace(/[^\w\s]/gi, ""),
          exp_month: parseInt(cardExpirationMonth),
          exp_year: parseInt(cardExpirationYear),
          holder_document: form.docNumber.replaceAll(".", ""),
          cvv: form.securityCode,
          label: form.cardholderName,
        },
        type: "card",
      };
      try {
        const token = await eventProvider.generatePagarmeToken(
          configs.PAGARME_APP_ID,
          cardTokenPayloadPg
        );
        return { error: false, token: token.data.id };
      } catch (error) {
        return { error: true, message: JSON.stringify(error) };
      }
    } else if (gateway === "mp") {
      const cardExpirationMonth = form.cardExpiration.split("/")[0];
      const cardExpirationYear = form.cardExpiration.split("/")[1].slice(-2);
      try {
        const token = await mp.createCardToken({
          ...form,
          cardExpirationMonth,
          cardExpirationYear,
        });
        return { error: false, token: token.id };
      } catch (error) {
        return {
          error: true,
          message: paymentService.handleMercadoPagoError(error),
        };
      }
    } else {
      return { error: true };
    }
  }

  async function pay() {
    setLoading(true);
    if (paymentForm === "pix") {
      const payload = {
        ...form,
        coupon_code: form.coupon_code === "" ? null : form.coupon_code,
        installments: parseInt(form.installments),
        tickets,
        buyer: {
          name: form.name,
          email: form.email,
          phone: form.phone,
          cpf: form.docNumber,
        },
      };
      eventProvider
        .payPix(event.id, payload)
        .then((res) => {
          setPix(res.data.message);
          handlePixStatus(res.data.message.id);
          trackPix();
          setLoading(false);
          setStep((prev) => prev + 1);
        })
        .catch((err) => {
          setLoading(false);
          if (
            err.response &&
            err.response.data &&
            err.response.data.response &&
            err.response.data.response.message
          ) {
            AlertInfo({
              dispatch,
              title:
                "Não foi possível gerar código PIX. Verifique os dados e tente novamente",
              message: err.response.data.response.message,
            });
          }
        });
    } else {
      const cardExpirationMonth = form.cardExpiration.split("/")[0];
      let cardExpirationYear = form.cardExpiration.split("/")[1];

      // gambiarra para ajustar anos de 2 dígitos
      if (cardExpirationYear.length === 2) {
        cardExpirationYear = `20${cardExpirationYear.toString()}`;
      }

      const payload = {
        ...form,
        tickets,
        coupon_code: form.coupon_code === "" ? null : form.coupon_code,
        cardExpirationMonth,
        cardExpirationYear,
        buyer: {
          name: form.name,
          email: form.email,
          phone: form.phone,
          cpf: form.docNumber,
        },
      };

      const card_token_mp = await generateToken("mp");
      const card_token_pg = await generateToken("pg");
      if (card_token_mp.error === false || card_token_pg.error === false) {
        if (card_token_pg.error) {
          payload.error_pagarme = card_token_pg?.message;
        }
        if (card_token_mp.error) {
          payload.error_mp = card_token_mp?.message;
        }
        payload.mercadopago_token = card_token_mp.token;
        payload.pagarme_token = card_token_pg.token;

        eventProvider
          .pay(event.id, payload)
          .then((res) => {
            setLoading(false);
            setStep((prev) => prev + 1);
            setPaid(true);
            trackCreditCrad();
          })
          .catch((err) => {
            setLoading(false);
            AlertConfirm({
              dispatch,
              title: "Sua compra não foi aprovada",
              message:
                "Sua compra foi recusada. Verifique seus dados e tente novamente. Caso prefira você pode pagar com Pix com aprovação imediata.",
              confirmText: "Pagar com pix",
              cancelText: "Tentar novamente",
              onConfirm: handlePayPix,
            });
          });
      } else {
        setLoading(false);
        if (card_token_mp.error) {
          AlertConfirm({
            dispatch,
            title: "Ops! Houve um erro ao validar cartão",
            message: card_token_mp.message,
            confirmText: "Pagar com pix",
            cancelText: "Tentar novamente",
            onConfirm: handlePayPix,
          });
        } else {
          AlertConfirm({
            dispatch,
            title: "Ops! Houve um erro ao validar cartão",
            message:
              "Sua compra foi recusada. Verifique seus dados e tente novamente. Caso prefira você pode pagar com Pix com aprovação imediata.",
            confirmText: "Pagar com pix",
            cancelText: "Tentar novamente",
            onConfirm: handlePayPix,
          });
        }
      }
    }
  }

  async function handlePayPix() {
    setPaymentForm("pix");
  }

  function handleStep() {
    if (step === 1) {
      setLoading(true);
      const timer = setTimeout(() => {
        setLoading(false);
        setForm((prev) => ({
          ...prev,
          name: user.name,
          email: user.email,
          docNumber: user.cpf,
          phone: user.phone,
        }));
      }, 1300);
    }
    if (step === 3) {
      if (paymentForm === "pix") {
        AlertConfirm({
          dispatch,
          title: "Confirmar",
          message: `O organizador do evento poderá solicitar a identidade do titular da compra 
                              na entrada do estabelecimento. Deseja confirmar a compra?`,
          confirmText: "Confirmar",
          onConfirm: pay,
        });
      } else {
        AlertConfirm({
          dispatch,
          title: "Confirmar",
          message: `O organizador do evento poderá solicitar a identidade e cartão de 
                             crédito do titular da compra na entrada do estabelecimento. Deseja confirmar a compra?`,
          confirmText: "Confirmar",
          onConfirm: pay,
        });
      }
    } else {
      setLoading(true);
      const timer = setTimeout(() => {
        setLoading(false);
        setStep((prev) => prev + 1);
      }, 1300);
    }
  }

  function removeStep() {
    setStep((prev) => prev - 1);
  }

  function handleChangeQuantity(ev, p) {
    const newtickes = [...tickets];
    if (newtickes.map((t) => t.id).includes(p.id)) {
      setTicket((prev) =>
        prev.map((item) => {
          if (item.id === p.id) {
            return { ...item, quantity: ev.target.value };
          }
          return item;
        })
      );
    } else {
      setTicket((prev) => [
        ...prev,
        {
          id: p.id,
          name: p.name,
          quantity: ev.target.value,
          price_format: p.price_format,
          rate: p.rate,
          rate_pix: p.rate_pix,
        },
      ]);
    }
  }

  function handleChangeInstallment(ev) {
    handleChange("installments", ev.target.value);
  }

  useEffect(() => {
    getTotal();
  }, [tickets]);

  useEffect(() => {
    if (
      event &&
      tickets
        .map((t) => t.quantity)
        .reduce((a, b) => parseInt(a) + parseInt(b), 0) > event.purchase_limit
    ) {
      AlertInfo({
        dispatch,
        title: "Quantidade excedida!",
        message: "Quantidade de ingressos acima do limite estabelecido.",
      });
    }
  }, [tickets, event]);

  function getTotal() {
    let total = 0;
    let total_pix = 0;
    let rate = 0;
    let rate_pix = 0;
    try {
      tickets.map((item) => {
        total += item.price_format * item.quantity;
        rate += item.rate * item.quantity;
        rate_pix += item.rate_pix * item.quantity;
        total_pix += item.price_format * item.quantity;
      });
    } catch (error) {
      console.log(error);
    }
    setForm({
      ...form,
      amount: total,
      amount_pix: total_pix,
      rate: rate,
      rate_pix: rate_pix,
    });
  }

  function getCEP() {
    generalProvider
      .fetchCEP(form.cep)
      .then((res) => {
        setForm({
          ...form,
          street: res.data.logradouro,
          neighborhood: res.data.bairro,
          city: res.data.localidade,
          state: res.data.uf,
        });
      })
      .catch((err) => console.log(err));
  }
  function validated() {
    if (step === 1) {
      return (
        tickets.length &&
        tickets
          .map((t) => t.quantity)
          .reduce((a, b) => parseInt(a) + parseInt(b), 0) <=
          event.purchase_limit &&
        event.event_purchase_types.filter((p) => validDateLimit(p)).length > 0
      );
    }
    if (step === 2) {
      return form.name !== "" && form.email !== "";
    }
    if (step === 3) {
      return (
        ((form.cardNumber !== "" &&
          form.cardholderName !== "" &&
          form.securityCode !== "" &&
          form.cardExpiration !== "" &&
          form.docNumber !== "" &&
          form.street !== "" &&
          form.number !== "" &&
          form.neighborhood !== "" &&
          form.cep !== "" &&
          form.city !== "" &&
          form.state !== "") ||
          paymentForm === "pix") &&
        form.accept_terms === true
      );
    }
  }
  const poll = async function (id, fn, fnCondition, ms) {
    let result = await fn(id);
    while (fnCondition(result)) {
      await wait(ms);
      result = await fn(id);
    }
    return result;
  };

  const wait = function (ms = 1000) {
    return new Promise((resolve) => {
      setTimeout(resolve, ms);
    });
  };

  const fetchPooling = (id) => eventProvider.getPixStatus(id);
  const validate = (result) => !result.data.pay;

  async function handlePixStatus(id) {
    const response = await poll(id, fetchPooling, validate, 5000);
    if (response.data.pay) {
      setPaid(true);
      setPix(null);
    }
  }

  function stepper() {
    return (
      <div className="d-flex stepper justify-content-center align-items-center my-5">
        <h5 className={`m-3 ${step >= 1 ? "" : "step-opacity"}`}>Ingressos </h5>
        <ArrowRight size={24} />
        <h5 className={`m-3 ${step >= 2 ? "" : "step-opacity"}`}>
          Identificação{" "}
        </h5>
        <ArrowRight size={24} />
        <h5 className={`m-3 ${step >= 3 ? "" : "step-opacity"}`}>Pagamento </h5>
      </div>
    );
  }

  function loadingContent() {
    return loading && <Loading />;
  }

  function validDateLimit(item) {
    return moment(item.date_limit).isSameOrAfter(moment());
  }

  function getCard(number) {
    let icon = "";
    if (number[0] === 5) {
      icon = "fa fa-cc-mastercard";
    } else if (number[0] === 4) {
      icon = "fa fa-cc-visa";
    } else {
      icon = "fa fa-credit-card";
    }
    return <i className={icon} />;
  }

  function copyToClipboard() {
    navigator.clipboard.writeText(pix.qr_code);
    feedbackService.showInfoMessage("Copiado com sucesso!");
  }

  function getBin() {
    return form.cardNumber.substring(0, 6);
  }

  async function getParcelInfo() {
    if (form.cardNumber !== "") {
      try {
        const response = await mp.getPaymentMethods({ bin: getBin() });
        const { results } = response;
        handleChange("payer_costs", results[0].payer_costs);
      } catch (error) {
        console.log("err", error);
      }
    }
  }

  function getFormatParcel(price) {
    let parcel = price.installments > 1 ? "parcelas" : "parcela";
    let total = getTotalCredit();
    let total_with_installment_rate =
      total + (total * price.installment_rate) / 100;
    let parcel_value = formatMoney(
      total_with_installment_rate / price.installments
    );
    let parcel_total = formatMoney(total_with_installment_rate);

    return `${price.installments} ${parcel} de ${parcel_value} (${parcel_total})`;
  }

  function handleCoupon(data) {
    setForm({
      ...form,
      coupon_discount: data.discount,
      coupon_code: data.coupon_code,
    });
  }
  function getTotalCredit() {
    return checkoutService.getTotal(
      form.amount,
      form.rate,
      form.coupon_discount
    );
  }
  function getTotalPix() {
    return checkoutService.getTotal(
      form.amount,
      form.rate_pix,
      form.coupon_discount
    );
  }
  function handleRemoveCoupon() {
    setForm({
      ...form,
      coupon_code: "",
      coupon_discount: "",
    });
  }

  if (!event) return null;

  return (
    <>
      {/* <EventCardFull item={event} /> */}
      <Container sx={{ mt: 2, mb: 2, minHeight: 400 }} maxWidth="md">
        {event.type_purchase === "link" ? (
          <div className="d-flex justify-content-end mt-3 mb-5">
            <Button
              onClick={() => window.open(event.url_pay, "_blank")}
              className="mt-3"
            >
              {event.url_pay_text}
            </Button>
          </div>
        ) : !user && event.event_purchase_types.length ? (
          <Row>
            <Col md={3} />
            <Col md={9}>
              <Card className="mt-3 mb-3">
                <Card.Body>
                  <div className="need-login">
                    <Image
                      style={{
                        width: 120,
                        height: 120,
                        objectFit: "cover",
                        marginBottom: 12,
                      }}
                      src={defaultUser}
                    />
                    <h5 className="my-1">
                      É necessário estar logado para efetuar compra de ingresso
                    </h5>
                    <Button
                      onClick={() =>
                        navigate(`/login/?backNav=/checkout/event/${event.id}`)
                      }
                      className="mt-3"
                    >
                      Ir para login
                    </Button>
                  </div>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        ) : (
          <>
            {!event.event_purchase_types.length ? null : stepper()}
            {step === 1 ? (
              !loading ? (
                <>
                  {event.event_purchase_types.length ? (
                    <div className="d-flex justify-content-end">
                      Limite de quantidade
                      <strong className="mx-1">{event.purchase_limit}</strong>
                    </div>
                  ) : null}
                  {event.event_purchase_types.map((p) => (
                    <Card className="mt-3 mb-3" key={uuid()}>
                      <Card.Body>
                        <Row>
                          <Col md={4}>
                            <>
                              <h4>{p.name}</h4>
                              <span>{formatMoney(p.price_format)} + TAXA</span>
                            </>
                          </Col>
                          {!validDateLimit(p) && (
                            <Col md={2}>
                              <Badge bg="secondary">
                                Vendas {p.name} encerreadas
                              </Badge>
                            </Col>
                          )}
                          <Col md={6}>
                            <Form.Select
                              value={
                                tickets.find((t) => t.id === p.id)
                                  ? tickets.find((t) => t.id === p.id).quantity
                                  : ""
                              }
                              onChange={(ev) => handleChangeQuantity(ev, p)}
                              disabled={!validDateLimit(p)}
                            >
                              <option>Selecione a quantidade</option>
                              {generateArray(
                                p.purchase_limit
                                  ? p.purchase_limit
                                  : p.remaining_amount,
                                p.minimum_quantity
                              ).map((opt) => (
                                <option key={uuid()} value={opt}>
                                  {opt}
                                </option>
                              ))}
                            </Form.Select>
                          </Col>
                        </Row>
                      </Card.Body>
                    </Card>
                  ))}
                </>
              ) : (
                loadingContent()
              )
            ) : step === 2 ? (
              <>
                {loading ? (
                  loadingContent()
                ) : (
                  <>
                    <Card className="mt-3 mb-3">
                      <Card.Body>
                        <Card.Title>Informações de contato</Card.Title>
                        <Card.Text />
                        <Form>
                          <Form.Group
                            className="mb-3"
                            controlId="formBasicNome"
                          >
                            <Form.Label>Nome *</Form.Label>
                            <Form.Control
                              value={form.name}
                              onChange={(ev) =>
                                handleChange("name", ev.target.value)
                              }
                              name="name"
                              type="text"
                            />
                            <Form.Text className="text-muted" />
                          </Form.Group>
                          <Form.Group
                            className="mb-3"
                            controlId="formBasicNome"
                          >
                            <Form.Label>Email *</Form.Label>
                            <Form.Control
                              value={form.email}
                              onChange={(ev) =>
                                handleChange("email", ev.target.value)
                              }
                              name="email"
                              type="text"
                            />
                            <Form.Text className="text-muted" />
                          </Form.Group>
                          <Form.Group
                            className="mb-3"
                            controlId="formBasicNome"
                          >
                            <Form.Label>Telefone *</Form.Label>
                            <Form.Control
                              value={form.phone}
                              onChange={(ev) =>
                                handleChange("phone", ev.target.value)
                              }
                              name="phone"
                              type="text"
                            />
                            <Form.Text className="text-muted" />
                          </Form.Group>
                        </Form>
                      </Card.Body>
                    </Card>
                  </>
                )}
              </>
            ) : step === 3 ? (
              !loading ? (
                <>
                  <PurchaseResume
                    tickets={tickets}
                    form={form}
                    handleRemoveCoupon={handleRemoveCoupon}
                  />

                  <Coupon
                    event_id={event.id}
                    tickets={tickets}
                    callback={handleCoupon}
                  />

                  <Card className="mt-3 mb-3">
                    <Card.Body>
                      <Card.Title>Como você prefere pagar?</Card.Title>
                      <Card.Text />
                      <Row>
                        <Col md={6}>
                          <Form.Check
                            type="radio"
                            name="paymentForm"
                            value="credit"
                            checked={paymentForm === "credit"}
                            onChange={(ev) => setPaymentForm("credit")}
                            label={
                              <div className="d-flex align-items-center payment-options-content">
                                <CreditCardIcon size={24} />
                                <span>Cartão de crédito</span>
                              </div>
                            }
                          />
                        </Col>
                        <Col md={6}>
                          <Form.Check
                            type="radio"
                            name="paymentForm"
                            value="pix"
                            checked={paymentForm === "pix"}
                            onChange={(ev) => setPaymentForm("pix")}
                            label={
                              <div className="d-flex align-items-center payment-options-content">
                                <PixIcon size={24} />
                                <span>PIX</span>
                                <p className="hint">(aprovação imediata)</p>
                              </div>
                            }
                          />
                        </Col>
                      </Row>
                    </Card.Body>
                  </Card>
                  {paymentForm === "credit" ? (
                    <>
                      <Card className="mt-3 mb-3">
                        <Card.Body>
                          <Card.Title>Pagamento</Card.Title>
                          <Card.Text />
                          <Form>
                            <Form.Group
                              className="mb-3"
                              controlId="formBasicNome"
                            >
                              <Form.Label>Número do cartão *</Form.Label>
                              <InputGroup className="mb-3">
                                <InputGroup.Text id="basic-addon1">
                                  {getCard(form.cardNumber)}
                                </InputGroup.Text>
                                <MaskedInputField
                                  mask="1111111111111111"
                                  className="form-control"
                                  name="cardNumber"
                                  size="3"
                                  type="text"
                                  onChange={(ev) =>
                                    handleChange("cardNumber", ev.target.value)
                                  }
                                  onBlur={() => getParcelInfo()}
                                  value={form.cardNumber}
                                />
                              </InputGroup>
                              <Form.Text className="text-muted" />
                            </Form.Group>
                            <Form.Group
                              className="mb-3"
                              controlId="formBasicNome"
                            >
                              <Form.Label>Nome impresso no cartão *</Form.Label>
                              <Form.Control
                                value={form.cardholderName}
                                onChange={(ev) =>
                                  handleChange(
                                    "cardholderName",
                                    ev.target.value
                                  )
                                }
                                name="cardholderName"
                                type="text"
                              />
                              <Form.Text className="text-muted" />
                            </Form.Group>
                            <Row>
                              <Col md={6}>
                                <Form.Group
                                  className="mb-3"
                                  controlId="formBasicNome"
                                >
                                  <Form.Label>Validade *</Form.Label>
                                  <MaskedInputField
                                    mask="11/1111"
                                    className="form-control"
                                    name="cardExpiration"
                                    size="6"
                                    type="text"
                                    onChange={(ev) =>
                                      handleChange(
                                        "cardExpiration",
                                        ev.target.value
                                      )
                                    }
                                    value={form.cardExpiration}
                                  />
                                  <Form.Text className="text-muted" />
                                </Form.Group>
                              </Col>
                              <Col md={6}>
                                <Form.Group
                                  className="mb-3"
                                  controlId="formBasicNome"
                                >
                                  <Form.Label>Código de segurança *</Form.Label>
                                  <MaskedInputField
                                    mask="111"
                                    className="form-control"
                                    name="securityCode"
                                    size="3"
                                    type="text"
                                    onChange={(ev) =>
                                      handleChange(
                                        "securityCode",
                                        ev.target.value
                                      )
                                    }
                                    value={form.securityCode}
                                  />
                                  <Form.Text className="text-muted" />
                                </Form.Group>
                              </Col>
                            </Row>

                            <Form.Group
                              className="mb-3"
                              controlId="formBasicNome"
                            >
                              <Form.Label>Parcelamento</Form.Label>
                              <Form.Select
                                value={form.installments}
                                onChange={handleChangeInstallment}
                              >
                                <option>Parcelas</option>
                                {form.payer_costs.map((price) => (
                                  <option
                                    key={uuid()}
                                    value={price.installments}
                                  >
                                    {getFormatParcel(price)}
                                  </option>
                                ))}
                              </Form.Select>
                            </Form.Group>

                            <Form.Group
                              className="mb-3"
                              controlId="formBasicNome"
                            >
                              <Form.Label>CPF *</Form.Label>
                              <Form.Control
                                value={form.docNumber}
                                onChange={(ev) =>
                                  handleChange("docNumber", ev.target.value)
                                }
                                name="docNumber"
                                type="text"
                              />
                              <Form.Text className="text-muted" />
                            </Form.Group>
                          </Form>
                        </Card.Body>
                      </Card>
                      <Card className="mt-3 mb-3">
                        <Card.Body>
                          <Card.Title>Endereço de cobrança</Card.Title>
                          <Card.Text />
                          <Row>
                            <Col md={4}>
                              <Form.Group
                                className="mb-3"
                                controlId="formBasicNome"
                              >
                                <Form.Label>CEP *</Form.Label>
                                <Form.Control
                                  value={form.cep}
                                  onChange={(ev) =>
                                    handleChange("cep", ev.target.value)
                                  }
                                  onBlur={() => getCEP()}
                                  name="cep"
                                  type="number"
                                  placeholder="Insira o cep"
                                />
                                <Form.Text className="text-muted" />
                              </Form.Group>
                            </Col>
                          </Row>
                          <Row>
                            <Col md={10}>
                              <Form.Group
                                className="mb-3"
                                controlId="formBasicNome"
                              >
                                <Form.Label>Rua *</Form.Label>
                                <Form.Control
                                  value={form.street}
                                  onChange={(ev) =>
                                    handleChange("street", ev.target.value)
                                  }
                                  name="street"
                                  type="text"
                                />
                                <Form.Text className="text-muted" />
                              </Form.Group>
                            </Col>
                            <Col md={2}>
                              <Form.Group
                                className="mb-3"
                                controlId="formBasicNome"
                              >
                                <Form.Label>Número *</Form.Label>
                                <Form.Control
                                  value={form.number}
                                  onChange={(ev) =>
                                    handleChange("number", ev.target.value)
                                  }
                                  name="number"
                                  type="number"
                                />
                                <Form.Text className="text-muted" />
                              </Form.Group>
                            </Col>
                          </Row>
                          <Form.Group
                            className="mb-3"
                            controlId="formBasicNome"
                          >
                            <Form.Label>Bairro *</Form.Label>
                            <Form.Control
                              value={form.neighborhood}
                              onChange={(ev) =>
                                handleChange("neighborhood", ev.target.value)
                              }
                              name="neighborhood"
                              type="text"
                            />
                            <Form.Text className="text-muted" />
                          </Form.Group>
                          <Row>
                            <Col md={10}>
                              <Form.Group
                                className="mb-3"
                                controlId="formBasicNome"
                              >
                                <Form.Label>Cidade *</Form.Label>
                                <Form.Control
                                  value={form.city}
                                  onChange={(ev) =>
                                    handleChange("city", ev.target.value)
                                  }
                                  name="city"
                                  type="text"
                                />
                                <Form.Text className="text-muted" />
                              </Form.Group>
                            </Col>
                            <Col md={2}>
                              <Form.Group
                                className="mb-3"
                                controlId="formBasicNome"
                              >
                                <Form.Label>Estato(UF) *</Form.Label>
                                <Form.Control
                                  value={form.state}
                                  onChange={(ev) =>
                                    handleChange("state", ev.target.value)
                                  }
                                  name="state"
                                  type="text"
                                />
                                <Form.Text className="text-muted" />
                              </Form.Group>
                            </Col>
                          </Row>
                          <Form.Group
                            className="mb-3"
                            controlId="formBasicNome"
                          >
                            <Form.Label>Complemento </Form.Label>
                            <Form.Control
                              value={form.complement}
                              onChange={(ev) =>
                                handleChange("complement", ev.target.value)
                              }
                              name="complement"
                              type="text"
                            />
                            <Form.Text className="text-muted" />
                          </Form.Group>
                        </Card.Body>
                      </Card>
                    </>
                  ) : null}
                  {paymentForm !== "" ? (
                    <Card className="mt-3 mb-3">
                      <Card.Body>
                        <Form.Check
                          type="checkbox"
                          name="accept_terms"
                          value={form.accept_terms}
                          checked={form.accept_terms === true}
                          onChange={(ev) =>
                            handleChange("accept_terms", ev.target.checked)
                          }
                          label={
                            <span>
                              <span style={{ marginRight: 2 }}>Aceitar</span>
                              <a
                                href="https://ingressos.makemynight.com.br/termos-de-privacidade"
                                target="blank"
                                className="p-1"
                              >
                                Termos de privacidade
                              </a>
                              ,
                              <a
                                href="https://ingressos.makemynight.com.br/termos-de-compra"
                                target="blank"
                                className="p-1"
                              >
                                Termos de compra
                              </a>{" "}
                              e
                              <a
                                href="https://ingressos.makemynight.com.br/termos-de-imagem"
                                target="blank"
                                className="p-1"
                              >
                                Termos de imagem
                              </a>
                            </span>
                          }
                        />
                      </Card.Body>
                    </Card>
                  ) : null}
                </>
              ) : (
                loadingContent()
              )
            ) : null}
            {paymentForm === "pix" && pix ? (
              <Card className="mt-3 mb-3">
                <Card.Body>
                  <Card.Title className="text-center mb-5">
                    Pague {formatMoney(getTotalPix())} via Pix para garantir sua
                    compra
                  </Card.Title>
                  <Row className="justify-content-md-center">
                    <Col md={6}>
                      <ListGroup as="ol" numbered>
                        <ListGroup.Item as="li">
                          Acesse seu Internet Banking ou app de pagamentos
                        </ListGroup.Item>
                        <ListGroup.Item as="li">
                          Escolha pagar via pix
                        </ListGroup.Item>
                        <ListGroup.Item as="li">
                          Cole o seguinte código
                        </ListGroup.Item>
                      </ListGroup>
                      <Card.Text className="mt-5 mb-3">
                        {" "}
                        Copie este código para pagar
                      </Card.Text>
                      <Card.Text className="mb-3">
                        Você poderá visualizar o beneficiário do pix{" "}
                        <strong>
                          Leonardo AR Desenvolvimento de App Ltda (Razão social
                          da plataforma do Makemynight)
                        </strong>
                      </Card.Text>
                      <InputGroup className="mt-3">
                        <FormControl
                          defaultValue={pix.qr_code}
                          aria-label="Username"
                          aria-describedby="basic-addon1"
                        />
                      </InputGroup>
                      {pix ? (
                        <img
                          src={`data:image/png;base64,${pix.qr_code_base64}`}
                          className="qrcode"
                          style={{ width: "100%" }}
                        />
                      ) : null}
                      <div className="text-center">
                        <Button
                          variant="primary"
                          size="sm"
                          className="mt-3"
                          onClick={() => copyToClipboard()}
                        >
                          Copiar código
                        </Button>
                        <Button
                          variant="text"
                          size="sm"
                          className="mt-3"
                          onClick={() => navigate("/my-events")}
                        >
                          Ver em Meus Ingressos
                        </Button>
                      </div>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            ) : null}

            {paid ? (
              <div
                className="mt-3 mb-3 pt-3 text-center final-stepper"
                style={{ backgroundColor: "#f9f9f9" }}
              >
                <div>
                  <span
                    className="my-5"
                    style={{ fontSize: 20, paddingRight: 12 }}
                  >
                    Pagamento efetuado com sucesso!
                  </span>
                  <Check2Circle size={60} />
                </div>
                <Button
                  className="my-5"
                  variant="primary"
                  size="lg"
                  onClick={() => navigate("/my-events")}
                >
                  Ir para meus ingressos{" "}
                </Button>
              </div>
            ) : null}

            {!event.event_purchase_types.length || step === 4 || paid ? null : (
              <div
                className="d-flex align-items-center justify-content-end mt-3"
                style={{ flexWrap: "wrap-reverse" }}
              >
                <Col
                  md={3}
                  xs={12}
                  className="d-flex align-items-center justify-content-end"
                >
                  <Button
                    variant="text"
                    size="lg"
                    disabled={step === 1}
                    onClick={() => removeStep()}
                  >
                    Voltar{" "}
                  </Button>
                </Col>
                <Col
                  md={3}
                  xs={12}
                  className="d-flex align-items-center justify-content-end"
                >
                  <Button
                    variant="primary"
                    size="lg"
                    disabled={!validated() || loading}
                    onClick={() => handleStep()}
                  >
                    {step === 3 ? "Pagar" : "Continuar"}
                  </Button>
                </Col>
              </div>
            )}

            {!event.event_purchase_types.length || step === 4 ? null : (
              <div className="d-flex align-items-center justify-content-between mt-3">
                <Image
                  src={ssl}
                  style={{ width: 160, height: 200, objectFit: "contain" }}
                />
              </div>
            )}
          </>
        )}
      </Container>
    </>
  );
}

export default Checkout;

import React, { useEffect, useState } from "react";
import {
  Container,
  Row,
  Col,
  Button,
  Modal,
  Form,
  Card,
  Breadcrumb,
  Badge,
  Toast,
  ToastContainer,
  Popover,
  OverlayTrigger,
} from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import { useNavigate, useParams } from "react-router-dom";
import QRCode from "react-qr-code";
import { InfoCircle } from "react-bootstrap-icons";
import "./ticket.css";
import {
  fetchRemoveTicket,
  fetchTickets,
  fetchTransferTicket,
} from "../../../providers/ticketProvider";

export function popover(title, description) {
  return (
    <Popover id="popover-basic">
      <Popover.Header as="h3">{title}</Popover.Header>
      <Popover.Body>
        <div
          dangerouslySetInnerHTML={{ __html: description }}
          className="remote-html-string"
        />
      </Popover.Body>
    </Popover>
  );
}

export function ModalConfirm({
  show,
  title,
  message,
  onClose,
  onConfirm,
  type,
}) {
  return (
    <Modal
      show={show}
      onHide={onClose}
      size="sm"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>{message}</p>
      </Modal.Body>
      <Modal.Footer>
        {type == "info" ? (
          <Button onClick={onClose} size="sm" className="btn-secondary">
            Ok
          </Button>
        ) : (
          <Button onClick={onConfirm} size="sm">
            Confirmar
          </Button>
        )}
      </Modal.Footer>
    </Modal>
  );
}

const defaultModal = {
  show: false,
  title: "",
  data: null,
  callback: null,
  type: "",
};
function Tickets() {
  const dispatch = useDispatch();
  let params = useParams();
  const navigate = useNavigate();
  const user = useSelector(({ auth }) => auth.user);
  const [data, setData] = useState([]);
  const [event, setEvent] = useState(null);
  const [modalShow, setModalShow] = useState(defaultModal);
  const [message, setMessage] = useState(null);
  useEffect(() => {
    if (user) {
      getTickets();
    }
  }, [user]);

  function getTickets() {
    fetchTickets(user.authentication_token)
      .then((res) => {
        let next = res.data.filter((d) => d.event.id == params.eventId);
        next = next.map((item) => {
          if (
            item.purchase_invite &&
            (item.purchase_invite.status == "pending" ||
              item.purchase_invite.status == "approved")
          ) {
            return { ...item, for_other: true };
          } else {
            return { ...item, for_other: false };
          }
        });
        if (next.length) {
          setEvent(next[0].event);
        }
        setData(next);
      })
      .catch((err) => console.log(err));
  }
  function handleOther(ev, item, for_other) {
    ev.preventDefault();
    let newdata = [...data];
    let list = newdata.map((d) => {
      if (d.id == item.id) {
        return { ...d, for_other: for_other };
      } else {
        return d;
      }
    });
    setData(list);
  }
  function handleChange(ev, label, item) {
    ev.preventDefault();
    let newdata = [...data];
    let list = newdata.map((d) => {
      if (d.id == item.id) {
        return {
          ...d,
          purchase_invite: { ...d.purchase_invite, [label]: ev.target.value },
        };
      } else {
        return d;
      }
    });
    setData(list);
  }
  function removeTicket(item) {
    fetchRemoveTicket(item.id, user.authentication_token)
      .then((res) => {
        setMessage({
          type: "success",
          title: "Convite devolvido com sucesso!",
          message: "",
        });
        setModalShow(defaultModal);
        getTickets();
      })
      .catch((err) => {
        setModalShow(defaultModal);
        setMessage({
          type: "danger",
          title: "Ops! Houve um problema ao devolver ingresso.",
          message:
            err.response && err.response.data && err.response.data.message
              ? err.response.data.message
              : "",
        });
      });
  }
  function confirmRemove(ev, item) {
    ev.preventDefault();
    setModalShow({
      callback: removeTicket,
      data: item,
      show: true,
      title: "Confirmar",
      message: `Deseja mesmo devolver o ingresso?`,
    });
  }
  function transferTicket(item) {
    if (item.status_buy == "approved") {
      fetchTransferTicket(
        item.id,
        { ...item.purchase_invite },
        user.authentication_token
      )
        .then((res) => {
          setMessage({
            type: "success",
            title: "Convite enviado por sucesso",
            message: "",
          });
          setModalShow(defaultModal);
          getTickets();
        })
        .catch((err) => {
          setModalShow(defaultModal);
          setMessage({
            type: "danger",
            title: "Ops! Houve um problema ao transferir ingresso.",
            message:
              err.response && err.response.data && err.response.data.message
                ? err.response.data.message
                : "",
          });
        });
    } else {
      setMessage({
        type: "danger",
        message:
          "Seu pagamento ainda nÃ£o foi aprovado. O QR code do ingresso gerado sÃ³ serÃ¡ vÃ¡lido apÃ³s a aprovaÃ§Ã£o. Para maiores dÃºvidas envie um email para contato@makemynight.com.br.",
      });
    }
  }
  function confirmTransfer(ev, item) {
    ev.preventDefault();
    setModalShow({
      callback: transferTicket,
      data: item,
      show: true,
      title: "Confirmar",
      message: `O organizador do evento poderÃ¡ solicitar a identidade e cartÃ£o do titular da compra. Deseja mesmo transferir o ingresso para ${item.purchase_invite.name}?`,
    });
  }
  function validatedTransfer(item) {
    return (
      (item.purchase_invite &&
        item.purchase_invite.name !== "" &&
        item.purchase_invite.name.split(" ").length &&
        item.purchase_invite.name.split(" ").filter((_r) => _r !== "").length >
          1 &&
        item.purchase_invite.email !== "" &&
        item.purchase_invite &&
        item.purchase_invite.status !== "approved" &&
        !item.presence_confirmed) ||
      (item.purchase_invite && item.purchase_invite.status == "pending")
    );
  }
  function checkName(_item) {
    if (
      !(
        _item.purchase_invite.name.split(" ").length &&
        _item.purchase_invite.name.split(" ").filter((_r) => _r !== "").length >
          1
      )
    ) {
      setModalShow({
        show: true,
        type: "info",
        title: "Aviso!",
        message: `Digite nome e sobrenome do convidado`,
      });
    }
  }
  function isPix(item) {
    return item.gateway === "mercadopago_pix";
  }
  function item(items) {
    return items.map((item) => {
      return (
        <Col md={6}>
          <Card>
            <Card.Header>
              Ingresso {item.event_purchase_type.name} #{item.id}{" "}
              {isPix(item) ? "[PIX]" : ""}
            </Card.Header>
            <Card.Body>
              {item.status_buy !== "approved" ? (
                <Row className="text-center my-3">
                  <Form.Text className="text-muted">
                    Aguardando pagamento.
                  </Form.Text>
                </Row>
              ) : (
                <>
                  <Row className="text-center my-3">
                    <Form.Text className="text-muted">
                      {item.transaction_code}
                    </Form.Text>
                  </Row>

                  <div className="d-flex justify-content-center">
                    <QRCode value={item.transaction_code} />
                  </div>

                  <Row className="text-center my-3">
                    {item.purchase_invite && item.purchase_invite.status && (
                      <Form.Text className="text-muted">
                        Status:{" "}
                        {item.purchase_invite.status == "approved"
                          ? "Aprovado! Obs: Ingresso jÃ¡ foi transferido para o convidado"
                          : item.purchase_invite.status == "pending"
                          ? "Aguardando aprovaÃ§Ã£o"
                          : ""}
                      </Form.Text>
                    )}
                  </Row>
                  <Row className="text-center my-3">
                    {item.can_return_ticket && item.is_ative && (
                      <Form.Text className="text-muted">
                        Ingresso enviado por {item.user.name}
                      </Form.Text>
                    )}
                  </Row>
                  <div className="d-flex justify-content-center">
                    <Button
                      className="custom-button-tab"
                      onClick={(ev) => handleOther(ev, item, false)}
                      size="sm"
                      variant={item.for_other ? "outline-primary" : "primary"}
                      type="submit"
                      disabled={
                        item.purchase_invite &&
                        (item.purchase_invite.status == "approved" ||
                          item.purchase_invite.status == "pending")
                      }
                    >
                      Meu ingresso
                    </Button>
                    <Button
                      className="custom-button-tab"
                      onClick={(ev) => handleOther(ev, item, true)}
                      size="sm"
                      variant={!item.for_other ? "outline-primary" : "primary"}
                      type="submit"
                      disabled={
                        item.purchase_invite &&
                        (item.purchase_invite.status == "approved" ||
                          item.purchase_invite.status == "pending")
                      }
                    >
                      Para convidado
                    </Button>
                  </div>
                  <Form className="my-3">
                    {item.for_other && (
                      <>
                        <Form.Text className="text-muted">
                          * Edite com o nome, sobrenome e email do convidado
                        </Form.Text>
                        <Form.Group className="mb-3" controlId="formBasicNome">
                          <Form.Label>Nome e sobrenome do convidado</Form.Label>
                          <Form.Control
                            onChange={(ev) => handleChange(ev, "name", item)}
                            value={
                              item["purchase_invite"]
                                ? item["purchase_invite"]["name"]
                                : ""
                            }
                            name="name"
                            disabled={
                              item.purchase_invite &&
                              item.purchase_invite.status == "approved"
                            }
                            type="text"
                            onBlur={() => checkName(item)}
                          />
                          <Form.Text className="text-muted"></Form.Text>
                          <Form.Label>Email do convidado</Form.Label>
                          <Form.Control
                            onChange={(ev) => handleChange(ev, "email", item)}
                            value={
                              item["purchase_invite"]
                                ? item["purchase_invite"]["email"]
                                : ""
                            }
                            name="email"
                            disabled={
                              item.purchase_invite &&
                              item.purchase_invite.status == "approved"
                            }
                            type="email"
                          />
                        </Form.Group>
                        <Button
                          disabled={!validatedTransfer(item)}
                          size="sm"
                          variant="primary"
                          type="submit"
                          onClick={(ev) => confirmTransfer(ev, item)}
                        >
                          Enviar ingresso
                        </Button>
                      </>
                    )}
                    {item.can_return_ticket &&
                      item.is_ative &&
                      !(
                        item.purchase_invite &&
                        item.purchase_invite.status == "approved"
                      ) && (
                        <Button
                          onClick={(ev) => confirmRemove(ev, item)}
                          size="sm"
                          variant="primary"
                          type="submit"
                        >
                          Devolver ingresso
                        </Button>
                      )}
                  </Form>
                </>
              )}
            </Card.Body>
          </Card>
        </Col>
      );
    });
  }
  return (
    event && (
      <>
        <ModalConfirm
          title={modalShow.title}
          message={modalShow.message}
          show={modalShow.show}
          onConfirm={() =>
            modalShow.callback ? modalShow.callback(modalShow.data) : null
          }
          onClose={() => setModalShow(defaultModal)}
        />
        {message && (
          <ToastContainer position="top-end" className="p-3">
            <Toast
              onClose={() => setMessage(null)}
              className="d-inline-block m-1"
              position="top-end"
              bg={message.type}
            >
              <Toast.Header>
                <strong className="me-auto">{message.title}</strong>
              </Toast.Header>
              <Toast.Body>{message.message}</Toast.Body>
            </Toast>
          </ToastContainer>
        )}
        <Container>
          <Breadcrumb style={{ marginTop: 50 }}>
            <Breadcrumb.Item onClick={() => navigate("/my-events")}>
              Meus eventos
            </Breadcrumb.Item>
            <Breadcrumb.Item active>Ingressos</Breadcrumb.Item>
          </Breadcrumb>
          <Card>
            <Card.Body>
              <Card.Text>
                <div className="d-flex align-items-center">
                  <div className="ms-2 me-auto">
                    {event.presence_confirmed && (
                      <Badge variant="primary" pill>
                        PresenÃ§a confirmada
                      </Badge>
                    )}
                    <div className="fw-bold">{event?.name}</div>
                    {event.venue ? event.venue.name : ""}{" "}
                    {moment(event.date).format("DD/MM HH:mm")}
                    <div>
                      {" "}
                      {event.user ? `Titular: ${event.user.name}` : ""}
                    </div>
                  </div>
                  <OverlayTrigger
                    trigger="click"
                    placement="bottom"
                    overlay={popover(event.name, event.description)}
                  >
                    <Button variant="text" className="p-0">
                      <InfoCircle size={22}></InfoCircle>
                    </Button>
                  </OverlayTrigger>
                </div>
              </Card.Text>
            </Card.Body>
          </Card>
          <Row className="mb-5">{item(data)}</Row>
        </Container>
      </>
    )
  );
}

export default Tickets;

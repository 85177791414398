import React, { useState, useEffect } from "react";
import { persistStore } from "redux-persist";
import { Container, Form, Button, Card, Row, Col } from "react-bootstrap";
import { CircularProgress } from "@mui/material";
import { useDispatch } from "react-redux";
import { useNavigate, Link, useLocation } from "react-router-dom";
import { AlertInfo } from "../../components/modal/AlertInfo";
import { store } from "../../store";
import * as provider from "../../providers/userProvider";
import feedbackService from "../../services/feedbackService";
import { setUserData, setToken } from "../../store/user/user.reducer";
import "./login.css";

const formDefault = {
  email: "",
  password: "",
};
function Login() {
  const { search } = useLocation();

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [form, setForm] = useState(formDefault);
  const [returnNav, setReturnNav] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (search) {
      const params = new URLSearchParams(search);
      const backNav = params.get("backNav");
      setReturnNav(backNav);
    }
  }, [search]);

  function submit(ev) {
    ev.preventDefault();
    setLoading(true);
    provider
      .login(form.email, form.password)
      .then((res) => {
        dispatch(setUserData(res.data.user));
        dispatch(setToken(res.data.user.authentication_token));
        persistStore(store, null, () => {
          setLoading(false);
          if (returnNav) {
            navigate(returnNav);
          } else {
            navigate("/");
          }
          feedbackService.showSuccessMessage("Login realizado com sucesso!");
        });
      })
      .catch((err) => {
        setLoading(false);
        AlertInfo({
          dispatch,
          title: "Ops! Houve um problema ao realizar login",
          message:
            err.response && err.response.data
              ? err.response && err.response.data
              : "Se o problema persistir, entre em contato via @makemynight.",
        });
      });
  }
  function isValidated() {
    return form.email !== "" && form.password !== "";
  }
  return (
    <Container>
      <div>
        <Row className="login-container mt-5">
          <Col md={5} sm={12}>
            <Card>
              <Row className="justify-content-center text-center mt-5">
                <h1>
                  <strong>Make</strong>
                  my
                  <strong>Night</strong>
                </h1>
              </Row>
              <Card.Body>
                <Form onSubmit={submit}>
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>Email</Form.Label>
                    <Form.Control
                      value={form.email}
                      onChange={(ev) =>
                        setForm({ ...form, email: ev.target.value })
                      }
                      name="email"
                      type="email"
                    />
                    <Form.Text className="text-muted" />
                  </Form.Group>

                  <Form.Group className="mb-3" controlId="formBasicPassword">
                    <Form.Label>Senha</Form.Label>
                    <Form.Control
                      value={form.password}
                      onChange={(ev) =>
                        setForm({ ...form, password: ev.target.value })
                      }
                      name="password"
                      type="password"
                      required
                    />
                  </Form.Group>
                  <Row className="justify-content-center mx-3 mt-5">
                    <Button
                      disabled={!isValidated()}
                      size="lg"
                      variant="primary"
                      type="submit"
                    >
                      {loading ? (
                        <CircularProgress size={20} color="secondary" />
                      ) : (
                        "Entrar"
                      )}
                    </Button>
                    <div className="d-flex justify-content-center mt-1">
                      <Button as={Link} to="/recover-password" variant="link">
                        Esqueci minha senha
                      </Button>
                    </div>
                    <div
                      className="text-center mt-4"
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                      }}
                    >
                      <span className="mb-1">Não possui conta?</span>
                      <Button
                        as={Link}
                        to={
                          returnNav
                            ? `/signup/?backNav=${returnNav}`
                            : "/signup"
                        }
                        variant="outline-primary"
                        size="sm"
                        type="submit"
                      >
                        Cadastre-se
                      </Button>
                    </div>
                  </Row>
                </Form>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </div>
    </Container>
  );
}
export default Login;

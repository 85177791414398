import React, { useEffect, useState } from "react";
import { Row, Col } from "react-bootstrap";
import { v4 as uuidv4 } from "uuid";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import CarouselComponent from "../../components/carousel/Carousel";
import VipListCardItem from "../../components/viplist/VipListCardItem";
import StateModal from "../../components/state-modal/StateModal";
import { fetchVipLists } from "../../providers/providers";
import { Container } from "@mui/material";
import {
  dismissPageLoading,
  setPageLoading,
} from "../../store/root/pageLoading.reducer";
import { AlertConfirm } from "../../components/modal/AlertConfirm";
import { BannerListaVipImage } from "../../assets/imgs";

function VipLists() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const state = useSelector(({ auth }) => auth.state);
  const user = useSelector(({ auth }) => auth.user);

  const [lists, setLists] = useState(null);

  useEffect(() => {
    if (state) {
      getData();
    }
  }, [state]);

  function getData() {
    dispatch(setPageLoading());
    fetchVipLists(1, state.uf)
      .then((res) => {
        setLists(res.data);
      })
      .catch((err) => {})
      .finally(() => dispatch(dismissPageLoading()));
  }

  function handleLogin(listaId) {
    AlertConfirm({
      dispatch,
      title: "Atenção",
      message: "É necessário estar logado para entrar na lista vip",
      confirmText: "Ir para login",
      cancelText: "Cancelar",
      onConfirm: () => navigate(`/login/?backNav=/lista-vip/${listaId}`),
    });
  }

  function handleEventDetail(list) {
    if (list.open_list) {
      navigate(`/lista-vip/${list.id}`, { state: { open: true } });
    } else {
      if (user) {
        navigate(`/lista-vip/${list.id}`);
      } else {
        handleLogin(list.id);
      }
    }
  }

  return (
    <div>
      <CarouselComponent banners={[BannerListaVipImage]} />
      <Container sx={{ mt: 2, mb: 2, minHeight: 400 }} maxWidth="md">
        {lists ? (
          <>
            <Row className="mt-5 mb-5">
              {lists.entries.map((list) => (
                <Col key={uuidv4()} sm={12} md={4}>
                  <VipListCardItem
                    item={list}
                    callback={() => handleEventDetail(list)}
                  />
                </Col>
              ))}
            </Row>
          </>
        ) : null}
      </Container>
      <StateModal />
    </div>
  );
}

export default VipLists;

import React from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { logout } from "../../store/user/user.reducer";
import { open } from "../../store/root/dialog/state-dialog.reducer";
import ResponsiveAppBar from "../header/Header";

function Navigation() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useSelector(({ auth }) => auth.user);
  const state = useSelector(({ auth }) => auth.state);

  function handleLogout() {
    dispatch(logout());
    navigate("/");
  }

  function handleOpenDialog() {
    dispatch(open());
  }

  return (
    <>
      <ResponsiveAppBar 
        state={state} 
        handleOpenDialog={handleOpenDialog}
        user={user}
        handleLogout={handleLogout}
      />
    </>
  );
}

export default Navigation;

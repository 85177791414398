import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import {SwipeableDrawer, Hidden} from '@mui/material';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { NavBrand } from './components/NavBrand';
import { AccountCircle } from '@mui/icons-material';

import './header.scss';
import { Divider } from '@mui/material';

import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ConfirmationNumberIcon from '@mui/icons-material/ConfirmationNumberOutlined';
import HomeIcon from '@mui/icons-material/HomeOutlined';
import FmdGoodOutlinedIcon from '@mui/icons-material/FmdGoodOutlined';
import SignupIcon from '@mui/icons-material/PersonAddAlt';
import ListAltIcon from '@mui/icons-material/ListAltOutlined';
import LogoutIcon from '@mui/icons-material/LogoutOutlined';
import LoginIcon from '@mui/icons-material/AccountCircleOutlined';
import CloseIcon from '@mui/icons-material/Close';
import MenuIcon from '@mui/icons-material/Menu';
import Button from '../button/MButton';
import { useNavigate } from 'react-router-dom';

const ResponsiveAppBar = ({state, handleOpenDialog, user, handleLogout}) => {
  const navigate = useNavigate();
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const [toggleDrawer, setToggleDrawer] = React.useState(false);

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const nav = (path) => {
    navigate(path);
    handleCloseUserMenu();
  }
  const navMobile = [ 
    {
      primary: 'Início',
      icon: <HomeIcon color='primary'/>,
      onClick: () => nav('/')
    },      
    {
      primary: 'Eventos',
      icon: <ConfirmationNumberIcon color='primary'/>,
      onClick: () => nav('/')
    },
    {
      primary: 'Lista VIP',
      icon: <ListAltIcon color='primary'/>,
      onClick: () => nav('/lista-vip'),
      divider: true
    },        
    {
      primary: 'Onde você está?',
      secondary: state ? state.name : 'Clique para selecionar',
      icon: <FmdGoodOutlinedIcon color='primary'/>,
      onClick: handleOpenDialog,
      divider: true
    }                   
  ]

  const navUserMobile = [   
    {
      primary: user ? user.name : 'Perfil',
      icon: <AccountCircle color='primary'/>,
      onClick: () => nav('/account')
    }, 
    { primary: 'Meus ingressos', icon: <ConfirmationNumberIcon color='primary'/>, onClick: () => nav('/my-events') },
    { primary: 'Minhas listas', icon: <ListAltIcon color='primary'/>, onClick: () => nav('/my-lists') },
    { primary: 'Sair', icon: <LogoutIcon color='primary'/>, onClick: () => handleLogout(),  divider: true },                                 
  ]

  const navLoginMobile = [   
    {
      primary: 'Login',
      icon: <LoginIcon color='primary'/>,
      onClick: () => nav('/login')
    },
    {
      primary: 'Cadastre-se',
      icon: <SignupIcon color='primary'/>,
      onClick: () => nav('/signup'),
      divider: true
    },                          
  ]

  const navsLeft = [
    {
      primary: 'Início',
      onClick: () => nav('/')
    },
    {
      primary: 'Lista VIP',
      onClick: () => nav('/lista-vip')
    },
    {
      primary: 'Comprar ingresso',
      onClick: () => nav('/'),
    },                           
  ]

  const navsRight = [
    {
      primary: 'Cadastre-se',
      onClick: () => nav('/signup')
    },
    {
      primary: 'Login',
      onClick: () => nav('/login')
    },                       
  ]

  const userNav = [
    { primary: 'Perfil', onClick: () => nav('/account') },
    { primary: 'Meus ingressos', onClick: () => nav('/my-events') },
    { primary: 'Minhas listas', onClick: () => nav('/my-lists') },
    { primary: 'Sair', onClick: () => handleLogout()},
  ];

  const renderStateButton = () => {
    return (
      <Button
        style={{ marginRight: 12 }}
        onClick={handleOpenDialog}
        variant="contained"
        size="medium"
        startIcon={<FmdGoodOutlinedIcon size={22} />}
      >
        {state ? state.name : "Selecione"}
      </Button>
    );
  };

  return (
    <>
    <Hidden smDown>
    <AppBar
        position='relative'
        sx={{
          backgroundColor: '#212529',
          paddingY: 1,
        }}
        >        
        <Toolbar>
          <NavBrand/>		
          <Box sx={{ flexGrow: 1, display: 'flex', marginLeft: 5 }}>
            {navsLeft.map((page, index) => (
              <Button
                key={index}
                onClick={page.onClick}
                sx={{ my: 2, color: 'white', display: 'block' }}
                variant='text'
              >
                {page.primary}
              </Button>
            ))}        
          </Box>
          <Box sx={{ flexGrow: 0,  display: 'flex', alignItems: 'center' }}>
            {renderStateButton()}            
            {user !== null ? (
                <>
                <Button
                  style={{ marginRight: 12 }}
                  onClick={() => nav('/my-events')}
                  variant="outlined"
                  size="medium"
                >
                  Meus ingressos
                </Button>

                <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                  <AccountCircle sx={{color: 'white'}} fontSize='large'/>
                </IconButton>
                <Menu
                  sx={{ mt: '45px' }}
                  id="menu-appbar"
                  anchorEl={anchorElUser}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  open={Boolean(anchorElUser)}
                  onClose={handleCloseUserMenu}
                >
                  {userNav.map((nav, index) => (
                    <MenuItem key={index} onClick={nav.onClick}>
                      <Typography textAlign="center">{nav.primary}</Typography>
                    </MenuItem>
                  ))}
                </Menu>                
                </>
              ) : (
                navsRight.map((page, index) => (
                  <Button
                    key={index}
                    onClick={page.onClick}
                    sx={{ my: 2, color: 'white', display: 'block' }}
                    variant='text'
                  >
                    {page.primary}
                  </Button>
                ))      
            )}
          </Box>                      		
        </Toolbar>        
    </AppBar>    
    </Hidden>
    <Hidden smUp>
      <AppBar
        position='relative'
        sx={{
          height: '80px',
          backgroundColor: '#212529',
          justifyContent: 'center'
        }}
        >        
        <Toolbar>
            <Box sx={{ position: 'relative', flex: 1 }}>
              <IconButton
                size='large'
                onClick={setToggleDrawer}
                sx={{color: 'white', position: 'absolute'}}
              >
                <MenuIcon />
              </IconButton>	              
              <NavBrand/>		
            </Box>			
            <Box sx={{ display: 'flex' }}>
            <SwipeableDrawer
              anchor={'left'}
              open={toggleDrawer}
              onClick={() => setToggleDrawer(false)}
              PaperProps={{
                sx:{minWidth: 240, backgroundColor: '#f9f9f9'}
              }}
            >
          <Toolbar
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-end',
              px: [1],
            }}
          >
            
            <IconButton onClick={setToggleDrawer}>
              <CloseIcon />
            </IconButton>
          </Toolbar>	
          <Divider />
          <List>             
            {
              user ? (
                navUserMobile.map((nav) => {
                  return (
                    <>
                    <ListItem disablePadding>        
                      <ListItemButton onClick={nav.onClick}>
                        {nav.icon && <ListItemIcon>
                          {nav.icon}
                        </ListItemIcon>}
                        <ListItemText 
                          primary={nav.primary}
                          secondary={nav.secondary ? nav.secondary : null}
                        />
                      </ListItemButton>
                    </ListItem> 
                    {nav.divider ? <Divider/> : null}
                    </>
                  )
                })                
              ) : (
                navLoginMobile.map((nav) => {
                  return (
                    <>
                    <ListItem disablePadding>        
                      <ListItemButton onClick={nav.onClick}>
                        {nav.icon && <ListItemIcon>
                          {nav.icon}
                        </ListItemIcon>}
                        <ListItemText 
                          primary={nav.primary}
                          secondary={nav.secondary ? nav.secondary : null}
                        />
                      </ListItemButton>
                    </ListItem> 
                    {nav.divider ? <Divider/> : null}
                    </>
                  )
                })                
              )
            } 
            {navMobile.map((nav) => {
              return (
                <>
                <ListItem disablePadding>        
                  <ListItemButton onClick={nav.onClick}>
                    {nav.icon && <ListItemIcon>
                      {nav.icon}
                    </ListItemIcon>}
                    <ListItemText 
                      primary={nav.primary}
                      secondary={nav.secondary ? nav.secondary : null}
                    />
                  </ListItemButton>
                </ListItem> 
                {nav.divider ? <Divider/> : null}
                </>
              )
            })}                                           
        </List>          
        </SwipeableDrawer>    
        </Box>        
        </Toolbar>        
   </AppBar>
   </Hidden>
    </>
  );
};
export default ResponsiveAppBar;

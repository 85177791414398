import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CircularProgress } from "@mui/material";
import { Box, Typography } from "@mui/material";
import { Form, Button, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Modal from "@mui/material/Modal";
import { setToken, setUserData } from "../../../store/user/user.reducer";
import LockIcon from "@mui/icons-material/Lock";
import * as provider from "../../../providers/userProvider";
import "./state.css";
import { persistStore } from "redux-persist";
import { store } from "../../../store";
import feedbackService from "../../../services/feedbackService";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  minWidth: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: 5,
  p: 4,
};

const formDefault = {
  email: "",
  password: "",
};

function LoginModal({
  returnNav,
  handleSignupModal,
  title = "Esse é um evento exclusivo!",
  subtitle = "Caso tenha recebido um email convite efetue login ou cadastro para acessar.",
  open = false,
}) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // const open = useSelector(({ root }) => root.stateDialog.open);
  const [form, setForm] = useState(formDefault);
  const [loading, setLoading] = useState(false);

  useEffect(() => {}, []);

  function isValidated() {
    return form.email !== "" && form.password !== "";
  }

  function submit(ev) {
    ev.preventDefault();
    setLoading(true);
    provider
      .login(form.email, form.password)
      .then((res) => {
        dispatch(setUserData(res.data.user));
        dispatch(setToken(res.data.user.authentication_token));
        persistStore(store, null, () => {
          setLoading(false);
          navigate(returnNav);
          feedbackService.showSuccessMessage("Login realizado com sucesso!");
        });
      })
      .catch((err) => {
        setLoading(false);
        feedbackService.showErrorMessage(
          err.response && err.response.data
            ? err.response && err.response.data
            : "Erro ao realizar login. Revise suas credenciais de login. Se o problema persistir, entre em contato via @makemynight."
        );
      });
  }

  return (
    <Modal
      open={open}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <div className="text-center">
          <LockIcon />
        </div>
        <Typography
          id="modal-modal-title"
          variant="h6"
          component="h2"
          marginBottom={2}
          fontWeight={900}
          textAlign={"center"}
        >
          {title}
        </Typography>
        <Typography
          id="modal-modal-title"
          variant="body"
          component="h6"
          marginBottom={5}
          textAlign={"center"}
        >
          {subtitle}
        </Typography>
        <Form onSubmit={submit}>
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label>Email</Form.Label>
            <Form.Control
              value={form.email}
              onChange={(ev) => setForm({ ...form, email: ev.target.value })}
              name="email"
              type="email"
            />
            <Form.Text className="text-muted" />
          </Form.Group>

          <Form.Group className="mb-3" controlId="formBasicPassword">
            <Form.Label>Senha</Form.Label>
            <Form.Control
              value={form.password}
              onChange={(ev) => setForm({ ...form, password: ev.target.value })}
              name="password"
              type="password"
              required
            />
          </Form.Group>

          <Row className="justify-content-center mx-3 mt-5">
            <Button
              disabled={!isValidated()}
              size="lg"
              variant="primary"
              type="submit"
            >
              {loading ? (
                <CircularProgress size={20} color="secondary" />
              ) : (
                "Entrar"
              )}
            </Button>
            <div className="d-flex justify-content-center mt-1">
              <Button as={null} to="/recover-password" variant="link">
                Esqueci minha senha
              </Button>
            </div>
            <div
              className="text-center mt-4"
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <span className="mb-1">Não possui conta?</span>
              <Button
                onClick={handleSignupModal}
                variant="outline-primary"
                size="sm"
                type="submit"
              >
                Cadastre-se
              </Button>
            </div>
          </Row>
        </Form>
      </Box>
    </Modal>
  );
}

export default LoginModal;

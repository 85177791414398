import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, Typography, MenuItem, FormControl, Select } from "@mui/material";
import Modal from "@mui/material/Modal";
import { setUserState } from "../../store/user/user.reducer";
import { close } from "../../store/root/dialog/state-dialog.reducer";
import { fetchStates } from "../../providers/generalProvider";
import Button from "../button/MButton";
import "./state.css";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  minWidth: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: 5,
  p: 4,
};

function StateModal() {
  const dispatch = useDispatch();
  const open = useSelector(({ root }) => root.stateDialog.open);
  const state = useSelector(({ auth }) => auth.state);
  const [states, setstates] = useState([]);
  const [currentState, setCurrentState] = useState(state || null);

  useEffect(() => {
    setCurrentState(state);
  }, [open, state]);

  const handleClose = () => {
    dispatch(close());
  };

  const handleConfirm = () => {
    dispatch(setUserState(currentState));
    handleClose();
  };

  const handleChangeState = (ev) => {
    let item = states.find((_state) => ev.target.value === _state.value);
    setCurrentState({
      uf: item.value,
      name: item.label,
    });
  };

  useEffect(() => {
    fetchStates().then((res) => {
      let items = Object.keys(res.data).map((key) => {
        return {
          value: key,
          label: res.data[key],
        };
      });
      setstates(items);
    });
  }, []);

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Typography
          id="modal-modal-title"
          variant="h6"
          component="h2"
          marginBottom={2}
        >
          Onde você está?
        </Typography>
        <FormControl fullWidth variant="standard">
          <Select
            sx={{
              "& .MuiSelect-select .notranslate::after": "Selecione"
                ? {
                    content: `"Selecione"`,
                    opacity: 0.42,
                  }
                : {},
            }}
            id="demo-simple-select"
            value={currentState ? currentState.uf : ""}
            onChange={handleChangeState}
            displayEmpty
            variant={"outlined"}
          >
            {states.map((_state, index) => {
              return (
                <MenuItem key={index} value={_state.value}>
                  {_state.label}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
        <div className="mt-3 d-flex justify-content-center">
          <Button onClick={handleConfirm}>CONFIRMAR</Button>
        </div>
      </Box>
    </Modal>
  );
}

export default StateModal;

import React, { useEffect, useState } from 'react';
import {
  Container
} from 'react-bootstrap';
import  {Grid, Tabs, Tab, Typography, Box } from '@mui/material';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import * as _ from 'lodash';
import { v4 as uuidv4 } from 'uuid';

import EventCardItem from '../../../components/event/EventCardItem';
import { fetchTickets } from '../../../providers/ticketProvider';

import './ticket.css';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function tabProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

function Tickets() {
  const navigate = useNavigate();
  const user = useSelector(({ auth }) => auth.user);
  const [data, setData] = useState([]);
  const [previous, setPrevious] = useState([]);
  const [value, setValue] = useState(0);

  useEffect(() => {
    if (user) {
      getTickets();
    }
  }, [user]);

  function getTickets() {
    fetchTickets().then((res) => {
      let next = res.data.filter((d) => moment().isSameOrBefore(moment(d.event.date)));
      const previous = res.data.filter((d) => moment().isAfter(moment(d.event.date)));
      next = next.map((item) => {
        if (item.purchase_invite && (item.purchase_invite.status === 'pending' || item.purchase_invite.status === 'approved')) {
          return { ...item, for_other: true };
        }
        return { ...item, for_other: false };
      });

      const grouped = _.groupBy(next, 'mercadopago_id');
      setData(grouped);

      const grouped_previous = _.groupBy(previous, 'mercadopago_id');
      setPrevious(grouped_previous);
    }).catch((err) => console.log(err));
  }
  function goToTickets(event) {
    navigate(`/my-events/${event.id}/tickets`, event);
  }
  const item = (items, previous_event = false) => {
    return (
      <Grid container spacing={2}>
        {Object.keys(items).map((d) => 
        <Grid item key={uuidv4()} md={3} className="mb-3">
          <EventCardItem
            item={items[d][0].event}
            callback={() => goToTickets(items[d][0].event)}
            type="my-tickets"
            able={items[d][0].status_buy === 'approved'}
          />
        </Grid>
        )}
      </Grid>
    );
  }
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleNoItem = () => {
    return (
      <Typography noWrap gutterBottom variant="p" component="div">
          Você ainda não possui ingressos comprados
      </Typography>
    )
  }
  return (
    <Container>
      <Box sx={{ width: '100%',  marginTop: 5, minHeight: '400px' }}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs value={value} onChange={handleChange} aria-label="tab de opções">
            <Tab label="Próximos eventos" {...tabProps(0)} />
            <Tab label="Eventos passados" {...tabProps(1)} />
          </Tabs>
        </Box>
        <TabPanel value={value} index={0}>
          <div className='row'>
            { !Object.keys(data).length && handleNoItem() }
            {item(data, true)}        
          </div>
        </TabPanel>
        <TabPanel value={value} index={1}>
          <div className='row'>
          { !Object.keys(previous).length && handleNoItem() }
            {item(previous, true)}
          </div>
        </TabPanel>
      </Box> 
    </Container>  
  );
}

export default Tickets;

import { createTheme } from '@mui/material';
import paletteBuilder from './theme/palette';

let colors = require(`./theme/colors.scss`);

const themeName = 'make-my-night';

const palette = paletteBuilder.getPaletteFromColors(colors);

const breakpoints = {
    values: {
        xs: 0,
        sm: 960,
        md: 1200,
        lg: 1600,
        xl: 1920,
    },
};

// const typography =  {
//     "fontFamily": '"Lato", sans-serif',
//     "fontSize": 12,
// }

const zIndex = {
    appBar: 1250,
};

export default createTheme({ themeName,  palette, breakpoints, zIndex });

import React, { useEffect, useState } from "react";
import {
  Container,
  Row,
  Col,
  Button,
  InputGroup,
  Form,
  Card,
  Image,
  Alert,
} from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { v4 as uuid } from "uuid";
import CustomInputFile from "../../../components/avatar/CustomInputFile";
import * as provider from "../../../providers/userProvider";
import { setUserData } from "../../../store/user/user.reducer";
import defaultImg from "../../../assets/imgs/default-image.png";
import defaultUser from "../../../assets/imgs/default-user.png";
import adService from "../../../services/adService";

const sexArr = [
  { id: "M", name: "Masculino" },
  { id: "F", name: "Feminino" },
  { id: "O", name: "Outro" },
];
function Profile() {
  const dispatch = useDispatch();
  const user = useSelector(({ auth }) => auth.user);
  const [form, setForm] = useState(user);
  const [message, setMessage] = useState({
    show: false,
    message: "",
    type: "",
  });
  const [imgPreview, setPreview] = useState(null);
  const [passPreview, setPassPreview] = useState(null);

  function handleUpdate(ev) {
    ev.preventDefault();
    provider
      .update(
        { ...form, picture: imgPreview, vaccine_passport: passPreview },
        user.authentication_token
      )
      .then((res) => {
        dispatch(setUserData({ ...user, ...form, img: res.data.img }));
        setMessage({
          show: true,
          message: "Atualizado com sucesso",
          type: "success",
        });
      })
      .catch((err) => {
        if (err.response && err.response.data) {
          setMessage({
            show: true,
            message: err.response.data.message,
            type: "danger",
          });
        } else {
          setMessage({
            show: true,
            message: "Ops! Houve um problema ao atualizar cadastro.",
            type: "danger",
          });
        }
      });
  }

  function isValidated() {
    return (
      form.email !== "" &&
      form.password !== "" &&
      form.password_confirm !== "" &&
      form.gender !== "" &&
      form.birthday !== "" &&
      form.cpf !== "" &&
      form.phone !== ""
    );
  }
  function onchangeimg(img) {
    setPreview(img);
  }
  function onchangepass(img) {
    setPassPreview(img);
  }
  useEffect(() => [adService.trackFb("PageView")]);
  return (
    <>
      <Container>
        <Card style={{ marginTop: 50 }}>
          <Card.Body>
            {!message.show ? null : (
              <Alert
                variant={message.type}
                onClose={() => setMessage({ show: false })}
                dismissible
              >
                {message.message}
              </Alert>
            )}
            <div className="d-flex justify-content-center align-items-center mb-5">
              <CustomInputFile
                id={"picture"}
                rounded
                img={
                  imgPreview
                    ? imgPreview
                    : form.img
                    ? form.img.url
                    : defaultUser
                }
                callback={onchangeimg}
              />
            </div>
            <Form onSubmit={handleUpdate}>
              <Form.Group className="mb-3" controlId="formBasicNome">
                <Form.Label>Nome *</Form.Label>
                <Form.Control
                  value={form.name}
                  onChange={(ev) => setForm({ ...form, name: ev.target.value })}
                  name="name"
                  type="text"
                />
                <Form.Text className="text-muted"></Form.Text>
              </Form.Group>
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>E-mail *</Form.Label>
                <Form.Control
                  value={form.email}
                  onChange={(ev) =>
                    setForm({ ...form, email: ev.target.value })
                  }
                  name="email"
                  type="email"
                  disabled
                />
                <Form.Text className="text-muted"></Form.Text>
              </Form.Group>
              <Form.Group className="mb-3" controlId="formBasicPhone">
                <Form.Label>Telefone *</Form.Label>
                <Form.Control
                  value={form.phone}
                  onChange={(ev) =>
                    setForm({ ...form, phone: ev.target.value })
                  }
                  name="phone"
                  type="text"
                />
                <Form.Text className="text-muted"></Form.Text>
              </Form.Group>
              <Form.Group className="mb-3" controlId="formBasicCPF">
                <Form.Label>CPF *</Form.Label>
                <Form.Control
                  value={form.cpf}
                  onChange={(ev) => setForm({ ...form, cpf: ev.target.value })}
                  name="cpf"
                  type="text"
                  disabled
                />
                <Form.Text className="text-muted"></Form.Text>
              </Form.Group>
              <Form.Group className="mb-3" controlId="formBasicCPF">
                <Form.Label>Data nascimento *</Form.Label>
                <Form.Control
                  value={form.birthday}
                  onChange={(ev) =>
                    setForm({ ...form, birthday: ev.target.value })
                  }
                  name="birthday"
                  type="text"
                />
                <Form.Text className="text-muted"></Form.Text>
              </Form.Group>
              <Form.Group className="mb-3" controlId="formBasicCPF">
                <Form.Label>GÃªnero</Form.Label>
                <Form.Select
                  value={form.gender}
                  onChange={(ev) =>
                    setForm({ ...form, gender: ev.target.value })
                  }
                >
                  <option>Selecione</option>
                  {sexArr.map((opt) => {
                    return (
                      <option key={uuid()} value={opt.id}>
                        {opt.name}
                      </option>
                    );
                  })}
                </Form.Select>
              </Form.Group>
              <Row>
                <Col md={6}>
                  <Form.Group className="mb-3" controlId="formBasicCPF">
                    <Form.Label>Facebook</Form.Label>
                    <InputGroup>
                      <InputGroup.Text>@</InputGroup.Text>
                      <Form.Control
                        value={form.facebook}
                        onChange={(ev) =>
                          setForm({ ...form, facebook: ev.target.value })
                        }
                        name="facebook"
                        type="text"
                      />
                    </InputGroup>
                    <Form.Text className="text-muted"></Form.Text>
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group className="mb-3" controlId="formBasicCPF">
                    <Form.Label>Instagram</Form.Label>

                    <InputGroup>
                      <InputGroup.Text>@</InputGroup.Text>
                      <Form.Control
                        value={form.instagram}
                        onChange={(ev) =>
                          setForm({ ...form, instagram: ev.target.value })
                        }
                        name="instagram"
                        type="text"
                      />
                    </InputGroup>
                    <Form.Text className="text-muted"></Form.Text>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col md={6}>
                  <CustomInputFile
                    id={"vaccine_passport"}
                    callback={onchangepass}
                    label={"Passaporte vacinal"}
                    width={"100%"}
                    height={200}
                    img={
                      passPreview
                        ? passPreview
                        : form.vaccine_passport
                        ? form.vaccine_passport.url
                        : defaultImg
                    }
                  />
                  <Form.Text className="text-muted">
                    NecessÃ¡rio para entrada nas listas e compra de ingressos
                  </Form.Text>
                </Col>
              </Row>
              <div className="d-flex justify-content-end">
                <Button
                  size="lg"
                  disabled={!isValidated()}
                  variant="primary"
                  type="submit"
                >
                  Salvar
                </Button>
              </div>
            </Form>
          </Card.Body>
        </Card>
      </Container>
    </>
  );
}

export default Profile;

import React from 'react';
import { v4 as uuid } from 'uuid';
import { Carousel } from 'react-bootstrap';
import Banner from '../banner/Banner';
import Loading from '../loading/Loading';
import Button from '../button/MButton';
import './carousel.css';

function CarouselComponent({ banners = [], loading = false, callback }) {
  
  if(loading) return <Loading/>;

  return (
    <Carousel>
      {banners.map((banner) => (
        <Carousel.Item key={uuid()}>
          <Banner 
            image={banner.photo_cover ? banner.photo_cover.url :  banner}
            alt={`Banner ${banner.name ? banner.name : 'página'}`}
          />
          {banner.event_purchase_types && banner.event_purchase_types.length && (
              <Carousel.Caption>
              <Button onClick={() => callback(banner)} 
                      size="sm" variant="primary" type="submit">Comprar agora</Button>
            </Carousel.Caption>
          )}
        </Carousel.Item>
      ))}
      </Carousel>
  );    
}

export default CarouselComponent;

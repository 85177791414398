import React from "react";
import { Box, Typography } from "@mui/material";
import { open, close } from "../../store/root/dialog/dialog.reducer";
import Button from "../button/MButton";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  minWidth: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: 5,
  p: 4,
  zIndex: 99,
};

export function AlertConfirm({
  dispatch,
  title,
  message,
  cancelText = "Cancelar",
  onCancel,
  confirmText = "Confirmar",
  onConfirm,
}) {
  function handleConfirm() {
    if (onConfirm) {
      onConfirm();
    }
    handleClose();
  }

  function handleClose() {
    if (onCancel) {
      onCancel();
    }
    dispatch(close());
  }

  return dispatch(
    open({
      children: (
        <Box sx={style}>
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            marginBottom={2}
            fontWeight={900}
          >
            {title}
          </Typography>
          <p className="my-3">{message}</p>
          <div className="mt-3 d-flex justify-content-end">
            <Button
              variant="text"
              color="secondary"
              onClick={handleClose}
            >
              {cancelText}
            </Button>
            <Button onClick={handleConfirm}>
              {confirmText} 
            </Button>
          </div>
        </Box>
      ),
    })
  );
}

import React, { useCallback, useEffect, useState } from "react";
import { Row, Col, Pagination } from "react-bootstrap";
import { Container } from "@mui/material";
import { v4 as uuidv4 } from "uuid";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import CarouselComponent from "../../components/carousel/Carousel";
import EventCardItem from "../../components/event/EventCardItem";
import Loading from "../../components/loading/Loading";
import StateModal from "../../components/state-modal/StateModal";
import * as eventProvider from "../../providers/eventProvider";
import "./home.css";
import {
  dismissPageLoading,
  setPageLoading,
} from "../../store/root/pageLoading.reducer";
import { BannerHomeImage } from "../../assets/imgs";

function Home() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const state = useSelector(({ auth }) => auth.state);

  const [events, setEvents] = useState(null);

  function resetEvents() {
    setEvents(null);
  }

  useEffect(() => {
    if (state) {
      resetEvents();
      getEvents();
    }
  }, [state]);

  function getEvents() {
    dispatch(setPageLoading());
    eventProvider
      .fetchEvents({
        state_code: state.uf,
      })
      .then((res) => {
        setEvents(res.data);
      })
      .catch((err) => console.log("err", err))
      .finally(() => dispatch(dismissPageLoading()));
  }

  function handleEventDetail(item) {
    navigate(`/event/${item.id}`, item);
  }

  function generateArray(number) {
    return Array.from(Array(number).keys(), (n) => n + 1);
  }

  const getBanners = useCallback(() => {
    return events
      ? [BannerHomeImage, ...events.entries.filter((e) => e.is_sponsored)]
      : [BannerHomeImage];
  }, [events]);

  return (
    <div className="home-container">
      <CarouselComponent
        banners={getBanners()}
        callback={handleEventDetail}
        loading={!events}
      />
      <Container sx={{ mt: 2, mb: 2, minHeight: 400 }} maxWidth="md">
        <h3 className="mt-5">Próximos eventos</h3>
        {events && (
          <Row className="mt-5 mb-5">
            {events.entries.map((event) => (
              <Col key={uuidv4()} sm={6} md={3} className="mb-3">
                <EventCardItem item={event} callback={handleEventDetail} />
              </Col>
            ))}
            {events.total_pages > 1 && (
              <div className="d-flex justify-content-end mt-5">
                <Pagination>
                  {generateArray(events.total_pages).map((p) => (
                    <Pagination.Item key={p} active={p === events.current_page}>
                      {p}
                    </Pagination.Item>
                  ))}
                </Pagination>
              </div>
            )}
          </Row>
        )}
      </Container>
      <StateModal />
    </div>
  );
}

export default Home;

import React, { useState } from "react";
import { Image, Row, Col } from "react-bootstrap";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Paper,
  Typography,
  Link,
  CircularProgress,
  Checkbox,
  FormControlLabel,
} from "@mui/material";
import PlaceOutlinedIcon from "@mui/icons-material/PlaceOutlined";
import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined";
import AccessTimeOutlinedIcon from "@mui/icons-material/AccessTimeOutlined";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import InfoIcon from "@mui/icons-material/InfoOutlined";

import Button from "../button/MButton";
import {
  formatDatetime,
  getDataDescription,
} from "../../application/util/dateUtil";
import { fetchEnterVipList, fetchExitVipList } from "../../providers/providers";
import { AlertConfirm } from "../../components/modal/AlertConfirm";
import { AlertInfo } from "../../components/modal/AlertInfo";
import feedbackService from "../../services/feedbackService";
import adService from "../../services/adService";
import "./style.css";
import { EnterOpenVipListModal } from "../../pages/viplists/EnterOpenVipListModal";

function VipListCardFull({ item, listaId, callbackAction }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useSelector(({ auth }) => auth.user);

  const [loading, setLoading] = useState(false);
  const [termsAccepted, setTermsAccepted] = useState(false);
  const [showModalOpenVipListModal, setShowModalOpenVipListModal] =
    useState(false);

  function validatedRegister() {
    if (
      user.cpf === "" ||
      user.cpf == null ||
      user.birthday === "" ||
      user.birthday == null
    ) {
      AlertConfirm({
        dispatch,
        title: "Ir para o perfil",
        message: "Para entrar na lista, complete seu cadastro!",
        confirmText: "Ir para o perfil",
        cancelText: "Cancelar",
        onConfirm: () => navigate(`/account`),
      });
      return false;
    } else {
      return true;
    }
  }

  function isUserBanned() {
    let dateToday = new Date();
    let userStillBanned =
      item.ban_until && new Date(item.ban_until) >= dateToday;
    if (userStillBanned) {
      AlertInfo({
        dispatch,
        title: "Sentimos sua falta no último evento! ",
        message: `Confimou e não veio 😔! Você poderá participar de novas listas a partir de ${moment(
          item.ban_until
        ).format("DD/MM")}.`,
        cancelText: "Ok",
      });
      return true;
    } else {
      return false;
    }
  }

  function handleVipList() {
    if (validatedRegister()) {
      if (!isUserBanned()) {
        if (item.concurrency) {
          AlertConfirm({
            dispatch,
            title: "Lista concorrente",
            message:
              "Ops! Acho que você já passou por aqui! Consta participação em lista de um outro evento no mesmo dia. Deseja continuar e descartar o evento anterior?",
            confirmText: "Descartar evento anterior",
            cancelText: "Cancelar",
            onConfirm: () => enter(),
          });
        } else {
          enter();
        }
      }
    }
  }

  function handleClick() {
    if (item.open_list) {
      handleOpenVipList();
    } else {
      handleVipList();
    }
  }

  const handleChange = (event) => {
    setTermsAccepted(event.target.checked);
  };

  function handleOpenVipList() {
    setShowModalOpenVipListModal(true);
  }

  function enter() {
    setLoading(true);
    fetchEnterVipList(item.id)
      .then((_data) => {
        setLoading(false);
        callbackAction();
        if (item.pixel) {
          adService.trackSingleCustom(item.pixel, "EnterList", {
            listId: item.id,
          });
        }
        feedbackService.showSuccessMessage(
          "Inscrição na lista realizada com sucesso!"
        );
      })
      .catch((resp) => {
        feedbackService.showErrorMessage(
          resp.error ? resp.error.msg : "Não foi possível entrar na lista."
        );
        setLoading(false);
      });
  }

  function handleExit() {
    setLoading(true);
    fetchExitVipList(item.id)
      .then((_data) => {
        setLoading(false);
        callbackAction();
        feedbackService.showSuccessMessage(
          "Inscrição na lista removida com sucesso!"
        );
      })
      .catch((resp) => {
        feedbackService.showErrorMessage(
          resp.error ? resp.error.msg : "Não foi possível sair da lista."
        );
        setLoading(false);
      });
  }

  function handleConfirmExit() {
    AlertConfirm({
      dispatch,
      title: "Confirmar saída lista vip",
      message: "Tem certeza que deseja sair da lista vip?",
      cancelText: "Cancelar",
      onConfirm: () => handleExit(),
    });
  }

  function handleEventDetailPageButton() {
    return (
      <Button
        variant="outlined"
        onClick={() => navigate(`/event/${item.event.id}`)}
        fullWidth
        className="mt-2"
      >
        Comprar ingresso
      </Button>
    );
  }

  function onCallbackEnterOpenVipList() {
    setShowModalOpenVipListModal(false);
    setTermsAccepted(false);
    callbackAction && callbackAction();
  }

  return (
    <>
      <Box sx={{ margin: 2 }}>
        <Row>
          <Col md={4}>
            <Image
              rounded
              src={item.banner.url}
              style={{
                width: "100%",
                height: 300,
                objectFit: "cover",
                backgroundColor: "#000",
              }}
            />
            <Box sx={{ display: "flex", justifyContent: "center", marginY: 2 }}>
              <Box sx={{ mt: 2, width: "100%" }}>
                {item.user_subscribe ? (
                  <>
                    <Button
                      variant="contained"
                      onClick={handleConfirmExit}
                      color="secondary"
                      fullWidth
                    >
                      {loading ? (
                        <CircularProgress size={20} color="secondary" />
                      ) : (
                        "Sair da lista"
                      )}
                    </Button>
                    {handleEventDetailPageButton()}
                  </>
                ) : item.show_subscribe_button ? (
                  <>
                    <Button
                      variant="contained"
                      onClick={handleClick}
                      disabled={
                        !item.able_subscribe_button || !termsAccepted || loading
                      }
                      fullWidth
                    >
                      {item.subscribe_button_text}
                    </Button>
                    {item.able_subscribe_button ? (
                      <FormControlLabel
                        className="d-block"
                        control={
                          <Checkbox
                            checked={termsAccepted}
                            onClick={handleChange}
                          />
                        }
                        label={
                          <>
                            Aceitar{" "}
                            <Link
                              href="https://ingressos.makemynight.com.br/termos-de-imagem"
                              rel="noreferrer"
                              target="_blank"
                            >
                              termos de imagem
                            </Link>
                          </>
                        }
                      />
                    ) : null}
                    {handleEventDetailPageButton()}
                  </>
                ) : null}
              </Box>
            </Box>
          </Col>
          <Col md={8}>
            <Paper sx={{ p: 5, mb: 2 }}>
              <Typography
                variant="h5"
                component={"div"}
                fontWeight={600}
                color="purple"
                display={"flex"}
                alignItems={"center"}
              >
                {item.title}
              </Typography>

              <Typography style={{ marginBottom: 16 }}>
                {item.event.venue}
              </Typography>

              <Typography
                paddingRight={1}
                gutterBottom
                display="flex"
                alignItems="center"
              >
                <InfoIcon fontSize="small" sx={{ marginRight: 1 }} />
                Lista inicia em {formatDatetime(item.start_at)}
              </Typography>

              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Typography
                  paddingRight={1}
                  gutterBottom
                  display="flex"
                  alignItems="center"
                >
                  <CalendarMonthOutlinedIcon
                    fontSize="small"
                    sx={{ marginRight: 1 }}
                  />
                  {getDataDescription(item.event.date)}
                </Typography>

                <Typography
                  paddingRight={1}
                  gutterBottom
                  display="flex"
                  alignItems="center"
                >
                  <AccessTimeOutlinedIcon
                    fontSize="small"
                    sx={{ marginRight: 1 }}
                  />
                  {moment(item.event.date).format("HH:mm")}
                </Typography>
              </Box>

              <Typography
                gutterBottom
                variant="p"
                component="div"
                display="flex"
                alignItems="center"
              >
                <PlaceOutlinedIcon fontSize="small" sx={{ marginRight: 1 }} />
                {item.event.address}
              </Typography>
            </Paper>

            <Paper sx={{ p: 5, mb: 2 }}>
              <Typography
                gutterBottom
                variant="p"
                component="div"
                sx={{ marginBottom: 3 }}
              >
                <InfoOutlinedIcon fontSize="small" sx={{ marginRight: 1 }} />
                Mais informações
              </Typography>

              <div
                dangerouslySetInnerHTML={{ __html: item.description }}
                className="remote-html-string"
              />
            </Paper>
          </Col>
        </Row>
      </Box>
      <EnterOpenVipListModal
        open={showModalOpenVipListModal}
        callback={onCallbackEnterOpenVipList}
        list={item}
        onCancel={() => setShowModalOpenVipListModal(false)}
      />
    </>
  );
}

export default VipListCardFull;

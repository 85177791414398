import React from 'react';
import { Facebook, Instagram } from 'react-bootstrap-icons';
import apple from '../../assets/imgs/btn-app-store-2e4e4d4ddb048446eb5875adaad9eb55a08ff823e9685eeac7e9dceed3b7525b.png';
import google from '../../assets/imgs/btn-google-play-4f1b0ae04791ed453be2d8e6ab091c975384e0c0672d80e5d5335298acadd65f.png';
import './footer.css';

function Footer() {
  return (
    <div id="footer">
      <footer className="bg-dark text-white">

        <div className="container p-1">

          <section className="" />

          <section className="my-5" />

          <section className="">
            <div className="row">
              <div className="col-lg-3 col-md-6 mb-4 mb-md-0">
                <h5 className="text-uppercase">Links</h5>

                <ul className="list-unstyled mb-0">
                  <li>
                    <a href="/" className="text-white">Início</a>
                  </li>
                  <li>
                    <a href="/" className="text-white">Política de privacidade</a>
                  </li>
                </ul>
              </div>

              <div className="col-lg-5 col-md-6 mb-4 mb-md-0">
                <h5 className="text-uppercase">Ingressos</h5>

                <ul className="list-unstyled mb-0">
                  <li>
                    Compre e gerencie os seus ingressos com QR Code.
                    Transfira ingressos pra amigos e gerencie sua carteira virtual.
                  </li>
                </ul>
              </div>

              <div className="col-lg-4 col-md-6 mb-4 mb-md-0">
                <h5 className="text-uppercase">Baixe agora</h5>
                <a target="_blank" href="https://itunes.apple.com/pt/app/makemynight/id828218175" role="button" rel="noreferrer">
                  <img style={{ width: 100, marginRight: 10 }} src={apple} />
                </a>
                <a target="_blank" href="https://play.google.com/store/apps/details?id=br.com.makemynight" role="button" rel="noreferrer">
                  <img style={{ width: 100 }} src={google} />
                </a>
              </div>
            </div>
          </section>

        </div>

        <section className="mb-2 mt-2 text-center">
          <a className="m-3" target="_blank" href="http://facebook.com/makemynight" role="button" rel="noreferrer">
            <Facebook size={24} style={{ color: 'white' }} />
          </a>
          <a className="m-3" target="_blank" href="http://instagram.com/makemynight" role="button" rel="noreferrer">
            <Instagram size={24} style={{ color: 'white' }} />
          </a>
        </section>

        <div className="text-center p-3">
          contato@makemynight.com.br
        </div>
        <div className="text-center p-3 bg-secondary text-dark">
          ©
          {' '}
          {new Date().getFullYear()}
          {' '}
          <strong>Make</strong>
          my
          <strong>Night</strong>
        </div>
      </footer>
    </div>
  );
}

export default Footer;

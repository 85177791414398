import { createSlice } from "@reduxjs/toolkit";

export const stateDialog = createSlice({
  name: "state_dialog",
  initialState: {
    open: false,
  },
  reducers: {
    open: (state, action) => ({
      open: true,
    }),
    close: (state, action) => ({
      open: false,
    }),
  },
});

// Action creators are generated for each case reducer function
export const { open, close } = stateDialog.actions;
export default stateDialog.reducer;

import React from "react";
import MaterialButton from "@mui/material/Button";
import { createTheme, ThemeProvider } from "@mui/material";

import "./button.scss";

export default function Button({
  children,
  variant = "contained",
  size = "md",
  ...props
}) {
  const theme = createTheme({
    palette: {
      primary: {
        main: "#58dd00",
      },
      secondary: {
        main: "#000",
      },
    },
    shape: {
      borderRadius: 25,
      padding: "5px 16px",
    },
    typography: {
      button: {
        fontWeight: 600,
        fontFamily: "Rubik-Regular",
        textTransform: 'uppercase'
      },
    },
  });

  return (
    <ThemeProvider theme={theme}>
      <MaterialButton variant={variant} size={size} {...props}>
        <React.Fragment>{children}</React.Fragment>
      </MaterialButton>
    </ThemeProvider>
  );
}

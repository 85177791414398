import React, { useState } from "react";
import { Form, InputGroup, Button, Row, Col } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { persistStore } from "redux-persist";
import { CircularProgress } from "@mui/material";
import MaskedInputField from "react-maskedinput";
import { useNavigate } from "react-router-dom";
import { v4 as uuid } from "uuid";
import * as provider from "../../../providers/userProvider";
import * as utils from "../../../utils";
import { setUserData, setToken } from "../../../store/user/user.reducer";
import defaultUser from "../../../assets/imgs/default-user.png";
import CustomInputFile from "../../../components/avatar/CustomInputFile";
import "../signup.css";
import { store } from "../../../store";
import feedbackService from "../../../services/feedbackService";
import { AlertInfo } from "../../../components/modal/AlertInfo";
import Modal from "@mui/material/Modal";
import { Box, Typography } from "@mui/material";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  minWidth: 400,
  height: "80%",
  overflow: "scroll",
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: 5,
  p: 4,
};

const formDefault = {
  email: "",
  password: "",
  password_confirm: "",
  name: "",
  gender: null,
  birthday: "",
  picture: null,
  vaccine_passport: null,
  cpf: "",
  facebook: "",
  instagram: "",
  phone: "",
};
const sexArr = [
  { id: "M", name: "Masculino" },
  { id: "F", name: "Feminino" },
  { id: "O", name: "Outro" },
];
function SignupModal({ handleLoginModal, returnNav, open = false }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  // const open = useSelector(({ root }) => root.stateDialog.open);
  const [form, setForm] = useState(formDefault);
  const [passwordValid, setPasswordValid] = useState(true);
  const [cpfValid, setCpfValid] = useState(true);
  const [imgPreview, setPreview] = useState(null);
  const [loading, setLoading] = useState(false);

  function submitLogin() {
    provider
      .login(form.email, form.password)
      .then((res) => {
        dispatch(setUserData(res.data.user));
        dispatch(setToken(res.data.user.authentication_token));
        persistStore(store, null, () => {
          setLoading(false);
          navigate(returnNav);
          feedbackService.showSuccessMessage("Login realizado com sucesso!");
        });
      })
      .catch((err) => {
        setLoading(false);
        feedbackService.showErrorMessage(
          err.response && err.response.data
            ? err.response && err.response.data
            : "Erro ao realizar login. Revise suas credenciais de login. Se o problema persistir, entre em contato via @makemynight."
        );
      });
  }

  function submit(ev) {
    ev.preventDefault();
    setLoading(true);
    provider
      .singup({ ...form, picture: imgPreview, vaccine_passport: null })
      .then((res) => {
        feedbackService.showSuccessMessage("Cadastro realizado com sucesso!");
        submitLogin();
      })
      .catch((err) => {
        setLoading(false);
        AlertInfo({
          dispatch,
          title: "Ops! Houve um problema ao realizar login",
          message:
            err.response && err.response.data
              ? err.response && err.response.data
              : "Se o problema persistir, entre em contato via @makemynight.",
        });
      });
  }
  function checkPasswords() {
    if (
      form.password !== form.password_confirm &&
      form.password !== "" &&
      form.password_confirm !== ""
    ) {
      setPasswordValid(false);
    } else {
      setPasswordValid(true);
    }
  }
  function checkCPF() {
    if (form.cpf !== "" && utils.validateCPF(form.cpf)) {
      setCpfValid(true);
    } else {
      setCpfValid(false);
    }
  }
  function onchangeimg(img) {
    setPreview(img);
  }
  // function onchangepass(img){
  //     setPassPreview(img)
  // }
  function isValidated() {
    return (
      form.email !== "" &&
      form.password !== "" &&
      form.password_confirm !== "" &&
      // form.gender !== '' &&
      form.birthday !== "" &&
      form.cpf !== "" &&
      form.phone !== "" &&
      passwordValid
    );
  }
  return (
    <Modal
      open={open}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Typography
          id="modal-modal-title"
          variant="h6"
          component="h2"
          marginBottom={2}
          fontWeight={900}
          textAlign={"center"}
        >
          Cadastre-se
        </Typography>
        <Form onSubmit={submit}>
          <div className="d-flex justify-content-center align-items-center">
            <CustomInputFile
              id="picture"
              rounded
              img={imgPreview || (form.img ? form.img.url : defaultUser)}
              callback={onchangeimg}
            />
          </div>
          <Form.Group className="mb-3" controlId="formBasicNome">
            <Form.Label>Nome *</Form.Label>
            <Form.Control
              value={form.name}
              onChange={(ev) => setForm({ ...form, name: ev.target.value })}
              name="name"
              type="text"
            />
            <Form.Text className="text-muted" />
          </Form.Group>
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label>E-mail *</Form.Label>
            <Form.Control
              value={form.email}
              onChange={(ev) => setForm({ ...form, email: ev.target.value })}
              name="email"
              type="email"
            />
            <Form.Text className="text-muted" />
          </Form.Group>
          <Form.Group className="mb-3" controlId="formBasicPhone">
            <Form.Label>Telefone *</Form.Label>
            <MaskedInputField
              mask="(11)111111111"
              className="form-control"
              name="phone"
              size="11"
              type="text"
              onChange={(ev) => setForm({ ...form, phone: ev.target.value })}
              value={form.phone}
            />
            <Form.Text className="text-muted" />
          </Form.Group>
          <Row>
            <Col md={6}>
              <Form.Group className="mb-3" controlId="formBasicPassword">
                <Form.Label>Senha *</Form.Label>
                <Form.Control
                  value={form.password}
                  onChange={(ev) =>
                    setForm({ ...form, password: ev.target.value })
                  }
                  name="password"
                  type="password"
                  required
                />
                <Form.Text className="text-muted">Mínimo 8 dígitos</Form.Text>
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group className="mb-3" controlId="formBasicPassword">
                <Form.Label>Confirmar senha *</Form.Label>
                <InputGroup hasValidation>
                  <Form.Control
                    value={form.password_confirm}
                    onChange={(ev) =>
                      setForm({
                        ...form,
                        password_confirm: ev.target.value,
                      })
                    }
                    onBlur={() => checkPasswords()}
                    name="password_confirm"
                    type="password"
                    required
                    isInvalid={!passwordValid}
                  />
                  <Form.Control.Feedback type="invalid">
                    Senha inválida.
                  </Form.Control.Feedback>
                </InputGroup>
              </Form.Group>
            </Col>
          </Row>
          <Form.Group className="mb-3" controlId="formBasicCPF">
            <Form.Label>CPF *</Form.Label>
            <InputGroup hasValidation>
              <Form.Control
                placeholder="___ ___ ___ __"
                className="form-control"
                name="cpf"
                onChange={(ev) => setForm({ ...form, cpf: ev.target.value })}
                value={form.cpf}
                isInvalid={!cpfValid}
                onBlur={() => checkCPF()}
              />
              <Form.Control.Feedback type="invalid">
                CPF inválido.
              </Form.Control.Feedback>
            </InputGroup>
            <Form.Text className="text-muted" />
          </Form.Group>
          <Form.Group className="mb-3" controlId="formBasicBirthdate">
            <Form.Label>Data nascimento *</Form.Label>
            <MaskedInputField
              mask="11/11/1111"
              className="form-control"
              name="birthday"
              size="8"
              type="text"
              onChange={(ev) => setForm({ ...form, birthday: ev.target.value })}
              value={form.birthday}
            />
            <Form.Text className="text-muted" />
          </Form.Group>
          <Form.Group className="mb-3" controlId="formBasicGender">
            <Form.Label>Gênero</Form.Label>
            <Form.Select
              value={form.gender}
              onChange={(ev) => setForm({ ...form, gender: ev.target.value })}
            >
              <option>Selecione</option>
              {sexArr.map((opt) => (
                <option key={uuid()} value={opt.id}>
                  {opt.name}
                </option>
              ))}
            </Form.Select>
          </Form.Group>
          <Row>
            <Col md={6}>
              <Form.Group className="mb-3" controlId="formBasicFacebook">
                <Form.Label>Facebook</Form.Label>
                <InputGroup>
                  <InputGroup.Text>@</InputGroup.Text>
                  <Form.Control
                    value={form.facebook}
                    onChange={(ev) =>
                      setForm({ ...form, facebook: ev.target.value })
                    }
                    name="facebook"
                    type="text"
                  />
                </InputGroup>
                <Form.Text className="text-muted" />
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group className="mb-3" controlId="formBasicInsta">
                <Form.Label>Instagram</Form.Label>
                <InputGroup>
                  <InputGroup.Text>@</InputGroup.Text>
                  <Form.Control
                    value={form.instagram}
                    onChange={(ev) =>
                      setForm({ ...form, instagram: ev.target.value })
                    }
                    name="instagram"
                    type="text"
                  />
                </InputGroup>
                <Form.Text className="text-muted" />
              </Form.Group>
            </Col>
          </Row>
          {/* <Row>
                        <Col md={12}>
                            <CustomInputFile id={"vaccine_passport"}
                                                callback={onchangepass}
                                                label={'Passaporte vacinal'}
                                                width={'100%'}
                                                height={200}
                                                img={passPreview ? passPreview : form.vaccine_passport ? form.vaccine_passport.url : defaultImg}/>
                            <Form.Text className="text-muted">
                                Necessário para entrada nas listas e compra de ingressos
                            </Form.Text>
                        </Col>
                    </Row>                               */}
          <Row className="justify-content-center mx-3 mt-5">
            <Button
              disabled={!isValidated()}
              size="lg"
              variant="primary"
              type="submit"
            >
              {loading ? (
                <CircularProgress size={20} color="secondary" />
              ) : (
                "Cadastrar"
              )}
            </Button>

            <div
              className="text-center mt-4"
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Button
                onClick={handleLoginModal}
                variant="outline-primary"
                size="sm"
                type="submit"
              >
                Voltar para Login
              </Button>
            </div>
          </Row>
        </Form>
      </Box>
    </Modal>
  );
}

export default SignupModal;

import React, { useEffect, useState } from "react";
import _ from "lodash";
import { useParams, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { Container } from "@mui/material";

import { fetchEvent } from "../../providers/eventProvider";
import feedbackService from "../../services/feedbackService";

import { AlertConfirm } from "../../components/modal/AlertConfirm";
import EventCardFull from "../../components/event/EventCardFull";
import Button from "../../components/button/MButton";
import BlurEventInfo from "../../components/event/BlurEventInfo";
import LoginModal from "../login/components/LoginModal";
import SignupModal from "../signup/components/SignupModal";

function EventDetail() {
  const params = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const user = useSelector(({ auth }) => auth.user);

  const { eventId } = params;

  const [event, setEvent] = useState(null);
  const [blurBackground, setBlurBackground] = useState(false);
  const [exclusive, setExclusive] = useState(false);
  const [login, setLogin] = useState(false);
  const [loading, setLoading] = useState(true);
  const [signup, setSignup] = useState(false);

  const debouncedGetEvent = _.debounce(getEvent, 300);

  useEffect(() => {
    debouncedGetEvent();

    return () => {
      debouncedGetEvent.cancel();
    };
  }, [params]);

  function getEvent() {
    fetchEvent(eventId)
      .then((res) => {
        setLoading(true);
        setBlurBackground(false);
        setEvent(res.data);
        setLogin(false);
        setSignup(false);
      })
      .catch((err) => {
        setBlurBackground(true);
        if (user && err.response.status === 403) {
          navigate(`/`);
          feedbackService.showInfoMessage(
            "Evento exclusivo! Você não tem permissão para acessar esse evento."
          );
        } else if (!user && err.response.status === 403) {
          setExclusive(true);
          setLogin(true);
        } else if (!user && err.response.status === 401) {
          setLogin(true);
        }
      })
      .finally(() => setLoading(false));
  }

  function handleCheckout() {
    if (user) {
      navigate(`/event/${event.id}/checkout`, event);
    } else {
      handleLogin(event.id);
    }
  }

  function handleLogin(eventId) {
    AlertConfirm({
      dispatch,
      title: "Atenção",
      message: "É necessário estar logado para efetuar essa ação.",
      confirmText: "Ir para login",
      cancelText: "Cancelar",
      onConfirm: () => navigate(`/login/?backNav=/event/${eventId}/checkout`),
    });
  }

  const actionEvent = () => {
    return (
      <Button
        variant="contained"
        fullWidth
        fontWeight={900}
        onClick={() => handleCheckout()}
      >
        {event.url_pay_text}
      </Button>
    );
  };
  function handleSignupModal() {
    setLogin(false);
    setSignup(true);
  }

  function handleLoginModal() {
    setSignup(false);
    setLogin(true);
  }

  return (
    <Container sx={{ mt: 2, mb: 2, minHeight: 400 }} maxWidth="md">
      {(loading || blurBackground) && <BlurEventInfo />}

      <LoginModal
        open={login}
        returnNav={`/event/${eventId}`}
        handleSignupModal={handleSignupModal}
        title={exclusive ? "Esse é um evento exclusivo!" : "Login"}
        subtitle={
          exclusive
            ? "Caso tenha recebido um email convite efetue login ou cadastro para acessar."
            : "Para acessar, é necessário efetuar o login."
        }
      />

      <SignupModal
        open={signup}
        returnNav={`/event/${eventId}`}
        handleLoginModal={handleLoginModal}
      />

      {event ? (
        <EventCardFull item={event} callbackAction={actionEvent()} />
      ) : null}
    </Container>
  );
}

export default EventDetail;

import React from 'react';
import { Container } from 'react-bootstrap';

function PrivacyTerms() {
  return (
    <div className="home-container">
      <Container>
        <div className="my-5">
          <h4><strong>SEÇÃO 1 - O QUE FAREMOS COM ESTA INFORMAÇÃO?</strong></h4>
          <p><span style={{ fontWeight: 400 }}>Quando você realiza algum cadastro ou executa alguma ação no aplicativo, como parte do processo de inscrição em lista VIP, resposta de pesquisas, visualização de fotos, emissão de cupons, coletamos as informações para melhoria e otimização de usabilidade ao usuário. As informações colhidas não são compartilhadas com terceiros ou utilizada para outros fins que não seja o direcionamento de usabilidade e exibição de conteúdo relacionado a eventos e cupons de acordo com as preferências do usuário durante o uso do aplicativo.</span></p>
          <p><span style={{ fontWeight: 400 }}>Email Marketing poderá ser realizado para avisos relacionados à inscrição em listas vip de eventos, emissão de cupons e anúncios institucionais e de parceiros do Makemynight. Nestes emails você poderá receber notícias, novos produtos e outras atualizações, além das confirmações de cupons e status de lista vip. A qualquer momento o usuário pode solicitar exclusão do seu email para disparo de email marketing.</span></p>
          <h4><strong>SEÇÃO 2 - CONSENTIMENTO</strong></h4>
          <p><span style={{ fontWeight: 400 }}>Como vocês obtêm meu consentimento?</span></p>
          <p><span style={{ fontWeight: 400 }}>Se pedimos por suas informações pessoais por uma razão secundária, como marketing, vamos lhe pedir diretamente por seu consentimento, ou lhe fornecer a oportunidade de dizer não.</span></p>
          <p><span style={{ fontWeight: 400 }}>E caso você queira retirar seu consentimento, como proceder?</span></p>
          <p>
            <span style={{ fontWeight: 400 }}>Se após você nos fornecer seus dados, você mudar de ideia, você pode retirar o seu consentimento para que possamos entrar em contato, para a coleção de dados contínua, uso ou divulgação de suas informações, a qualquer momento, entrando em contato conosco em </span>
            <strong />
            <a href="mailto:leonardo@makemynight.com.br"><strong>contato@makemynight.com.br</strong></a>
            <span style={{ fontWeight: 400 }}>.</span>
          </p>
          <h4><strong>SEÇÃO 3 - DIVULGAÇÃO</strong></h4>
          <p><span style={{ fontWeight: 400 }}>Podemos divulgar suas informações pessoais caso sejamos obrigados pela lei para fazê-lo ou se você violar nossos Termos de Serviço.</span></p>
          <h4><strong>SEÇÃO 4 - SERVIÇOS DE TERCEIROS</strong></h4>
          <p><span style={{ fontWeight: 400 }}>No geral, os fornecedores terceirizados usados por nós irão apenas coletar, usar e divulgar suas informações na medida do necessário para permitir que eles realizem os serviços que eles nos fornecem.</span></p>
          <p><span style={{ fontWeight: 400 }}>Uma vez que você deixe o site da nossa loja ou seja redirecionado para um aplicativo ou site de terceiros, você não será mais regido por essa Política de Privacidade ou pelos Termos de Serviço do nosso site.</span></p>
          <p><span style={{ fontWeight: 400 }}>Links</span></p>
          <p><span style={{ fontWeight: 400 }}>Quando você clica em links para compra de ingressos, eles podem lhe direcionar para fora do nosso site. Não somos responsáveis pelas práticas de privacidade de outros sites e lhe incentivamos a ler as declarações de privacidade deles.</span></p>
          <h4><strong>SEÇÃO 5 - SEGURANÇA</strong></h4>
          <p><span style={{ fontWeight: 400 }}>Para proteger suas informações pessoais, tomamos precauções razoáveis e seguimos as melhores práticas da indústria para nos certificar que elas não serão perdidas inadequadamente, usurpadas, acessadas, divulgadas, alteradas ou destruídas.</span></p>
          <h4><strong>SEÇÃO 6 - ALTERAÇÕES PARA ESSA POLÍTICA DE PRIVACIDADE</strong></h4>
          <p><span style={{ fontWeight: 400 }}>Reservamos o direito de modificar essa política de privacidade a qualquer momento, então por favor, revise-a com frequência. Alterações e esclarecimentos vão surtir efeito imediatamente após sua publicação no site. Se fizermos alterações de materiais para essa política, iremos notificá-lo aqui que eles foram atualizados, para que você tenha ciência sobre quais informações coletamos, como as usamos, e sob que circunstâncias, se alguma, usamos e/ou divulgamos elas.</span></p>
          <p><span style={{ fontWeight: 400 }}>Se nossa loja for adquirida ou fundida com outra empresa, suas informações podem ser transferidas para os novos proprietários para que possamos continuar a vender produtos para você.</span></p>
        </div>
      </Container>
    </div>
  );
}

export default PrivacyTerms;

import httpClientMethod from './httpClientMethod';

export function fetchTickets() {
  return httpClientMethod.get('/events/purchases.json');
}

export function fetchTransferTicket(ticket, params) {
  return httpClientMethod.post(`/events/purchases/${ticket}/transfer`, params);
}

export function fetchRemoveTicket(ticket) {
  return httpClientMethod.post(`/events/purchases/${ticket}/give_back`);
}

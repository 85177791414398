import httpClientMethod from "./httpClientMethod";

export function fetchStates() {
  return httpClientMethod.get("/states");
}

export function fetchCEP(cep) {
  return httpClientMethod.get(`/ws/${cep}/json/`, {
    baseURL: "https://viacep.com.br",
  });
}

import React from "react";
import { Row, Col } from "react-bootstrap";
import { Box, Paper } from "@mui/material";
import "./event.css";

function BlurEventInfo() {
  return (
    <>
      <Box sx={{ margin: 2 }}>
        <Row>
          <Col md={4}>
            <div
              style={{
                width: "100%",
                height: 300,
                background: "#E2E5E7",
                borderRadius: 5,
              }}
            ></div>
          </Col>
          <Col md={8}>
            <Paper sx={{ p: 5, mb: 2, backgroundColor: "#E2E5E7" }}>
              <div
                style={{
                  height: 10,
                  backgroundColor: "#f9f9f9",
                  borderRadius: 5,
                }}
              ></div>
              <div
                style={{
                  marginTop: 16,
                  height: 10,
                  backgroundColor: "#f9f9f9",
                  borderRadius: 5,
                  width: "70%",
                }}
              ></div>
              <Box sx={{ display: "flex", marginTop: 3, width: "50%" }} gap={1}>
                <div
                  style={{
                    height: 10,
                    backgroundColor: "#f9f9f9",
                    borderRadius: 5,
                    width: "100%",
                  }}
                ></div>
                <div
                  style={{
                    height: 10,
                    backgroundColor: "#f9f9f9",
                    borderRadius: 5,
                    width: "100%",
                  }}
                ></div>
              </Box>
              <div
                style={{
                  marginTop: 16,
                  height: 10,
                  backgroundColor: "#f9f9f9",
                  borderRadius: 5,
                  width: "90%",
                }}
              ></div>
            </Paper>

            <Paper sx={{ p: 5, mb: 2, height: 400, background: "#E2E5E7" }}>
              <div
                style={{
                  height: 10,
                  backgroundColor: "#f9f9f9",
                  borderRadius: 5,
                }}
              ></div>
              {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((line) => {
                return (
                  <div
                    style={{
                      marginTop: 16,
                      height: 10,
                      backgroundColor: "#f9f9f9",
                      borderRadius: 5,
                      width: "70%",
                    }}
                  ></div>
                );
              })}
            </Paper>
          </Col>
        </Row>
      </Box>
    </>
  );
}

export default BlurEventInfo;

import React from "react";

import moment from "moment";

import {
  Box,
  Card,
  CardActions,
  CardContent,
  CardActionArea,
  CardMedia,
  Typography,
} from "@mui/material";

import Button from "../button/MButton";
import PlaceOutlinedIcon from "@mui/icons-material/PlaceOutlined";
import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined";
import AccessTimeOutlinedIcon from "@mui/icons-material/AccessTimeOutlined";
import { getDataDescription } from "../../application/util/dateUtil";

function EventCardItem({ item, type = "home", able = true, callback }) {
  const buttonText = item?.event_purchase_types?.length
    ? "Comprar ingresso"
    : "Ver detalhes";
  const buttonColor = item?.event_purchase_types?.length
    ? "primary"
    : "secondary";

  const getTicketButton = () => {
    return type === "my-tickets" ? (
      <Button disabled={!able} color="secondary" onClick={() => callback(item)}>
        Ver ingresso
      </Button>
    ) : null;
  };

  const getHomeButton = () => {
    return type === "home" ? (
      <Button color={buttonColor} onClick={() => callback(item)}>
        {buttonText}
      </Button>
    ) : null;
  };

  return (
    <Card>
      <CardActionArea onClick={() => callback(item)}>
        {item.photo_cover && (
          <CardMedia
            component="img"
            alt="banner evento"
            image={item.photo_cover.medium.url}
            sx={{ objectFit: "contain" }}
          />
        )}
        <CardContent>
          <Typography
            noWrap
            gutterBottom
            component="div"
            fontWeight={600}
            color={"purple"}
            sx={{
              maxWidth: "100%",
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {item.name}
          </Typography>

          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Typography
              paddingRight={1}
              gutterBottom
              display="flex"
              alignItems="center"
            >
              <CalendarMonthOutlinedIcon
                fontSize="small"
                sx={{ marginRight: 1 }}
              />
              {getDataDescription(item.date)}
            </Typography>

            <Typography
              paddingRight={1}
              gutterBottom
              display="flex"
              alignItems="center"
            >
              <AccessTimeOutlinedIcon
                fontSize="small"
                sx={{ marginRight: 1 }}
              />
              {moment(item.date).format("HH:mm")}
            </Typography>
          </Box>

          <Typography
            gutterBottom
            variant="p"
            component="div"
            display="flex"
            alignItems="center"
          >
            <PlaceOutlinedIcon fontSize="small" sx={{ marginRight: 1 }} />
            {item.venue["name"]
              ? item.venue.name
              : item.venue
              ? item.venue
              : ""}
          </Typography>

          {!able ? (
            <Typography gutterBottom variant="p" component="div">
              Aguardando pagamento
            </Typography>
          ) : null}
        </CardContent>
      </CardActionArea>
      <CardActions sx={{ justifyContent: "center", marginBottom: 2 }}>
        {getHomeButton()}
        {getTicketButton()}
      </CardActions>
    </Card>
  );
}

export default EventCardItem;

import React from "react";
import {
  Box,
  Typography,
  InputLabel,
  MenuItem,
  FormControl,
  Select,
} from "@mui/material";
import { open, close } from "../../store/root/dialog/dialog.reducer";
import Button from "../button/MButton";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  minWidth: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: 5,
  p: 4,
};

export function AlertInfo({
  dispatch,
  title,
  message,
  confirmText = "Ok",
  onConfirm,
}) {
  function handleConfirm() {
    if (onConfirm) {
      onConfirm();
    }
    dispatch(close());
  }

  return dispatch(
    open({
      children: (
        <Box sx={style}>
          <Typography
            id="modal-modal-title"
            className="text-center"
            variant="h6"
            component="h2"
            marginBottom={2}
            fontWeight={900}
          >
            {title}
          </Typography>
          <Typography id="modal-modal-title" variant="body1" marginBottom={2}>
            {message}
          </Typography>

          <div className="mt-3 d-flex justify-content-center">
            <Button onClick={handleConfirm}>
            OK
            </Button>
          </div>
        </Box>
      ),
    })
  );
}

import { createStore, combineReducers } from 'redux';
import storage from 'redux-persist/lib/storage';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';
import { persistStore, persistReducer } from 'redux-persist';

import rootReducer from './store/root/index';
import authReducer from './store/user/user.reducer';

const persistConfig = {
  key: 'user',
  storage,
  stateReconciler: autoMergeLevel2, // see "Merge Process" section for details.
};

const authReducerPersist = persistReducer(persistConfig, authReducer);

const reducer = combineReducers({
  auth: authReducerPersist,
  root: rootReducer,
});

export const store = createStore(reducer);
export const persistor = persistStore(store);

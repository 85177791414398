import React from "react";
import QRCode from 'react-qr-code';
import  { Box } from '@mui/material';
import { open, close } from "../../store/root/dialog/dialog.reducer";
import Button from "../button/MButton";

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    minWidth: 400,
    bgcolor: "background.paper",
    boxShadow: 24,
    borderRadius: 5,
    p: 4,
};

  
export function VipListQRCodeModal({
  dispatch,
  code = '',
}) {
  return dispatch(
    open({
      children: (
        <Box sx={style}>
            <div className='d-flex justify-content-center'>
                <QRCode value={code} /> 
            </div> 
        </Box>               
      ),
    })
  );
}

import { createSlice } from "@reduxjs/toolkit";

export const dialog = createSlice({
  name: "dialog",
  initialState: {
    open: false,
    options: {
      children: <></>,
    },
  },
  reducers: {
    open: (state, action) => ({
      open: true,
      options: {
        ...state.options,
        ...action.payload,
      },
    }),
    close: (state, action) => ({
      open: false,
      options: {
        children: <></>,
      },
    }),
  },
});

// Action creators are generated for each case reducer function
export const { open, close } = dialog.actions;
export default dialog.reducer;

import React, { useState } from 'react';
import {
  Container, Form, Button, Card, Row, Col, Alert, Spinner,
} from 'react-bootstrap';

import * as provider from '../../providers/userProvider';
import './recover-password.css';

const formDefault = {
  email: '',
};
function RecoverPassword() {
  const [form, setForm] = useState(formDefault);
  const [message, setMessage] = useState(null);
  const [loading, setLoading] = useState(false);

  function submit(ev) {
    ev.preventDefault();
    setLoading(true);
    setMessage(null);
    provider.recoverAccount(form.email).then((res) => {
      setLoading(false);
      setForm(formDefault);
      setMessage({ variant: 'success', text: 'Você receberá um link via email para redefinir sua senha' });
    }).catch((err) => {
      setLoading(false);
      if (err.response && err.response.data) {
        setMessage({ variant: 'danger', text: err.response.data.error });
      } else {
        setMessage({ variant: 'danger', text: 'Ops! Houve um problema ao tentar recuperar senha.' });
      }
    });
  }
  function isValidated() {
    return (
      form.email !== ''
    );
  }
  function loadingContent() {
    return (
      <div className="d-flex align-items-center justify-content-center">
        <Spinner animation="grow" className="m-1" size="sm" />
        <Spinner animation="grow" className="m-1" size="sm" />
        <Spinner animation="grow" className="m-1" size="sm" />
      </div>
    );
  }
  return (
    <Container>
      <div>
        <Row className="recover-container mt-5">
          <Col md={5} sm={12}>
            <Card>
              <Row className="justify-content-center text-center mt-5">
                <h1>
                  <strong>Make</strong>
                  my
                  <strong>Night</strong>
                </h1>
              </Row>
              {message && (
                <Alert variant={message.variant} className="mx-3">
                  {message.text}
                </Alert>
              )}
              <Card.Body>
                <Form onSubmit={submit}>
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>Digite seu e-mail</Form.Label>
                    <Form.Control
                      value={form.email}
                      onChange={(ev) => setForm({ ...form, email: ev.target.value })}
                      name="email"
                      type="email"
                    />
                    <Form.Text className="text-muted" />
                  </Form.Group>
                  <Row className="justify-content-center mx-3 mt-5">

                    <Button disabled={!isValidated()} size="lg" variant="primary" type="submit">
                      {loading ? loadingContent() : 'Recuperar'}
                    </Button>
                  </Row>
                </Form>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </div>
    </Container>
  );
}
export default RecoverPassword;

import api from './api';

export function login(email, password) {
  const headers = {
    'Content-Type': 'application/x-www-form-urlencoded',
    Accept: 'application/json',
  };
  const urlSearchParams = `user[email]=${email}&user[password]=${password}`;
  return api.post('/users/sign_in.json', urlSearchParams, headers);
}
export function recoverAccount(email) {
  const headers = {
    'Content-Type': 'application/x-www-form-urlencoded',
    Accept: 'application/json',
  };
  const urlSearchParams = `email=${email}`;
  return api.post('/users/reset_passwords.json', urlSearchParams, headers);
}

export function singup(payload) {
  return api.post('users.json', payload);
}
export function update(payload, token) {
  const urlSearchParams = {
    name: payload.name,
    gender: payload.gender,
    birthday: payload.birthday,
    picture: payload.picture,
    vaccine_passport: payload.vaccine_passport,
    cpf: payload.cpf,
    facebook: payload.facebook,
    instagram: payload.instagram,
    phone: payload.phone,
  };
  const _headers = {
    Accept: 'application/json',
    'User-Token': token,
  };

  return api.put('/users/update.json', urlSearchParams, { headers: _headers });
}

import React from "react";
import moment from "moment";
import { Image, Row, Col } from "react-bootstrap";
import { Box, Paper, Typography } from "@mui/material";
import PlaceOutlinedIcon from "@mui/icons-material/PlaceOutlined";
import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined";
import AccessTimeOutlinedIcon from "@mui/icons-material/AccessTimeOutlined";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { getDataDescription } from "../../application/util/dateUtil";
import "./event.css";

function EventCardFull({ item, callbackAction }) {
  return (
    <>
      <Box sx={{ margin: 2 }}>
        <Row>
          <Col md={4}>
            {item.photo_cover && (
              <Image
                rounded
                src={item.photo_cover.medium.url}
                style={{
                  width: "100%",
                  height: 300,
                  objectFit: "cover",
                  backgroundColor: "#000",
                }}
              />
            )}
            <Box sx={{ display: "flex", justifyContent: "center", marginY: 2 }}>
              <React.Fragment>{callbackAction}</React.Fragment>
            </Box>
          </Col>
          <Col md={8}>
            <Paper sx={{ p: 5, mb: 2 }}>
              <Typography
                variant="h5"
                component={"div"}
                gutterBottom
                fontWeight={600}
                color="purple"
                display={"flex"}
                alignItems={"center"}
              >
                {item.name}
              </Typography>
              <Typography gutterBottom variant="p">
                {item.venue}
              </Typography>

              <Box sx={{ display: "flex", alignItems: "center", marginTop: 2 }}>
                <Typography
                  paddingRight={1}
                  gutterBottom
                  display="flex"
                  alignItems="center"
                >
                  <CalendarMonthOutlinedIcon
                    fontSize="small"
                    sx={{ marginRight: 1 }}
                  />
                  {getDataDescription(item.date)}
                </Typography>

                <Typography
                  paddingRight={1}
                  gutterBottom
                  display="flex"
                  alignItems="center"
                >
                  <AccessTimeOutlinedIcon
                    fontSize="small"
                    sx={{ marginRight: 1 }}
                  />
                  {moment(item.date).format("HH:mm")}
                </Typography>
              </Box>

              <Typography
                gutterBottom
                variant="p"
                component="div"
                display="flex"
                alignItems="center"
              >
                <PlaceOutlinedIcon fontSize="small" sx={{ marginRight: 1 }} />
                {item.address}
              </Typography>
            </Paper>

            <Paper sx={{ p: 5, mb: 2 }}>
              <Typography gutterBottom variant="p" component="div">
                <InfoOutlinedIcon fontSize="small" sx={{ marginRight: 1 }} />
                Mais informações
              </Typography>
              <div
                dangerouslySetInnerHTML={{ __html: item.description }}
                className="remote-html-string"
              />
            </Paper>
          </Col>
        </Row>
      </Box>
    </>
  );
}

export default EventCardFull;

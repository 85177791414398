import React, {useCallback} from 'react';
import { v4 as uuid } from 'uuid';
import { Card } from 'react-bootstrap';
import { Chip, Typography } from '@mui/material';
import * as moneyUtil from '../../../application/util/moneyUtil';
import checkoutService from '../checkoutService';

export default function PurchaseResume({ tickets, form, handleRemoveCoupon }) {
    const formatMoney = moneyUtil.formatMoney;

    const methotCard = (method, value, options={}) => {
        return (
            <div className="d-flex justify-content-between">
                <h6>
                    {method}
                </h6>
                <Typography {...options}>{value}</Typography>
            </div>
        )
    }

    const handleCoupon = useCallback(() => {
        if(form.coupon_code !== '') return (
            <Chip
                label={`Cupom de ${form.coupon_discount}% aplicado`}
                color='primary'
                size='small'
                onDelete={() => handleRemoveCoupon()} 
            />
        )
    }, [form]);

    const totalCredit = useCallback(() => {
        return formatMoney(checkoutService.getTotal(form.amount, form.rate, form.coupon_discount))
    }, [form]);

    const totalPIX = useCallback(() => {
        return formatMoney(checkoutService.getTotal(form.amount, form.rate_pix, form.coupon_discount))
    }, [form]);

    return (
        <Card className="mt-3 mb-3">
            <Card.Body>
            <Card.Title>Resumo da compra</Card.Title>
            <Card.Text />
                <hr />
                <h4 className="mb-3">Ingressos</h4>
                {tickets.map((t) => (
                    <div className="mb-3" key={uuid()}>
                        <h6>
                            {t.quantity} x {t.name} {formatMoney(t.price_format)} 
                        </h6>
                    </div>
                ))}
                <hr />
                <div className="d-flex justify-content-start align-items-center mb-3">
                    <h4 className='m-0'>Total</h4>           
                </div> 

                <div className="d-flex justify-content-start align-items-center my-3">
                    <h5 className='m-0'>Cartão de crédito</h5>           
                </div>
                
                {methotCard('Ingresso(s)', `${formatMoney(form.amount)}`)}
                {methotCard('Taxa', `${formatMoney(form.rate)}`)}
                {methotCard('Subtotal', `${formatMoney(form.amount + form.rate)}`)}
                {form.coupon_code !== '' && methotCard('Desconto', handleCoupon(), {color: 'primary'})}
                {methotCard('Total', `${totalCredit()}`)}

                <div className="d-flex justify-content-start align-items-center my-3">
                    <h5 className='m-0'>PIX</h5>           
                </div>
                
                {methotCard('Ingresso(s)', `${formatMoney(form.amount)}`)}
                {methotCard('Taxa', `${formatMoney(form.rate_pix)}`)}
                {methotCard('Subtotal', `${formatMoney(form.amount + form.rate_pix)}`)}
                {form.coupon_code !== '' && methotCard('Desconto', handleCoupon(), {color: 'primary'})}
                {methotCard('Total', `${totalPIX()}`)}                
            </Card.Body>
        </Card>
    );
}

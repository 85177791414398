import React from 'react';
import moment from 'moment';

import {Card, CardContent, CardMedia, Typography, Box, CardActionArea} from '@mui/material';
import PlaceOutlinedIcon from '@mui/icons-material/PlaceOutlined';
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import AccessTimeOutlinedIcon from '@mui/icons-material/AccessTimeOutlined';

import { getDataDescription } from '../../application/util/dateUtil';

function VipListCardItem({item, userActionsComponent, callback}) {
  return (
    <Card sx={{ marginBottom: 2 }}>
      <CardActionArea onClick={() => callback()} sx={{ display: 'flex', maxHeight: 150 }}>
      <CardMedia
        component="img"
        alt="banner lista"
        sx={{ width: '40%', height: 150 }}
        image={item.banner.url}
      />
      <CardContent sx={{ width: '60%' }}>
        <Box>
          <Typography 
            component='div'
            fontWeight={600}
            color={'purple'}
            gutterBottom
            sx={{
              maxWidth: '90%',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              display: 'block'              
          }}          
            >
            {item.event.name}
          </Typography>
          
          <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: 1 }}>
            <Typography paddingRight={1} display='flex' alignItems='center' variant="body1">
              <CalendarMonthOutlinedIcon fontSize="small"  sx={{marginRight: 1}}/>
              {getDataDescription(item.event.date)}
            </Typography>

            <Typography paddingRight={1} display='flex' alignItems='center' variant="body1">
              <AccessTimeOutlinedIcon fontSize="small" sx={{marginRight: 1}}/>
              {moment(item.event.date).format('HH:mm')}
            </Typography>
          </Box>    

          <Typography variant="body1"  component="div" sx={{
                maxWidth: '90%',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                display: 'block'
          }}>
            <PlaceOutlinedIcon fontSize="small" sx={{marginRight: 1}}/>
            {item.event.venue.name  ? item.event.venue.name : item.event.venue}
          </Typography>   
          </Box>
      </CardContent>
      </CardActionArea>
      {userActionsComponent ? (<React.Fragment>{userActionsComponent}</React.Fragment>) : null}
    </Card>
  );
}

export default VipListCardItem;

import React, { useState } from 'react';
import { Card, Form } from 'react-bootstrap';
import { fetchCouponEvent } from '../../../providers/eventProvider';
import Button from '../../../components/button/MButton';
import feedbackService from '../../../services/feedbackService';

export default function Coupon({ event_id, tickets, callback }) {
  const [code, setCode] = useState('');

  const handleChange = (ev) => {
    setCode(ev.target.value);
  };
  
  async function submit() {
    let payload = {
      tickets: tickets.map((_t) => {return {id: _t.id, quantity: parseInt(_t.quantity)}})
    }
    try {
      const response = await fetchCouponEvent(event_id, code, payload); 
      feedbackService.showSuccessMessage('Cupom aplicado com sucesso!');
      setCode('');
      callback({discount: response.data.discount, coupon_code: code});
    } catch (error) {
      const { message } = error.response.data;
      feedbackService.showErrorMessage(message);
    }
  }
  return (
    <Card>
      <Card.Body>
        <div className="d-flex align-items-center justify-content-between">
          <h5>Possui cupom de desconto?</h5>
          <div className="d-flex align-items-center">
            <Form.Group controlId="formBasicNome" className="mx-3">
              <Form.Control
                value={code}
                placeholder="Código"
                onChange={handleChange}
                name="code"
                type="text"
              />
            </Form.Group>
            <Button variant='text' onClick={submit} disabled={code === ''}>
              APLICAR
            </Button>
          </div>
        </div>
      </Card.Body>
    </Card>
  );
}

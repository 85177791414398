export default {
  handleMercadoPagoError(err) {
    let erros = [];
    if (err) {
      if (Array.isArray(err)) {
        erros = err;
      } else if (typeof (err) === 'object') {
        erros = err.cause;
      }
    }

    erros = erros.map((item) => item.code);

    if (erros.includes('E301')) {
      return 'Revise o número do seu cartão.';
    }
    if (erros.includes('E302')) {
      return 'Confira o código de segurança.';
    }
    if (erros.includes('316')) {
      return 'Por favor, digite um nome válido.';
    }
    if (erros.includes('324')) {
      return 'Confira o seu cpf.';
    }
    if (erros.includes('E204')) {
      return 'Por favor, confira o mês de validade.';
    }
    if (erros.includes('E205')) {
      return 'Por favor, confira o ano de validade.';
    }
    return 'Verifique todos os dados do cartão.';
  },
};

import logoImg from '../../../assets/imgs/logo.png';
import Link from '@mui/material/Link';

export const NavBrand = () => {
    return (
        <Link underline="none" href="/">
            <div className="d-flex d-block justify-content-center align-items-center app-main-header">
                <img
                    src={logoImg}
                    width="55"
                    height="55"
                    alt="React Bootstrap logo"
                    style={{ marginRight: 12 }}
                />
                <div className="d-none d-sm-block header-logo-text">
                    <strong>Make</strong>My<strong>Night</strong>
                </div> 
        </div>    
      </Link>  
    )
}
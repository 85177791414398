import httpClientMethod from "./httpClientMethod";

export function fetchEvents({ page = 1, per_page = 100, state_code = "" }) {
  return httpClientMethod.get(
    `/events.json?page=${page}&per_page=${per_page}&state_code=${state_code}`
  );
}

export function fetchEvent(id) {
  return httpClientMethod.get(`/events/${id}.json`);
}

export function fetchCouponEvent(event_id, code, data) {
  return httpClientMethod.post(`/events/${event_id}/coupons/${code}`, data);
}

export function pay(event_id, data) {
  return httpClientMethod.post(`/events/${event_id}/purchase`, data);
}

export function payPix(event_id, data) {
  return httpClientMethod.post(`/events/${event_id}/purchase_pix`, data);
}

export function getPixStatus(id) {
  return httpClientMethod.get(`/events/status_pix/${id}`);
}

export function generatePagarmeToken(app_id, data) {
  return httpClientMethod.post(`/core/v5/tokens?appId=${app_id}`, data, {
    baseURL: "https://api.pagar.me",
  });
}

import React from 'react';
import GroupAddOutlinedIcon from '@mui/icons-material/GroupAddOutlined';
import QrCodeOutlinedIcon from '@mui/icons-material/QrCodeOutlined';
import {List, ListItem, ListItemButton, 
        ListItemIcon, ListItemText, Divider} from '@mui/material';

function VipListUserCardActions({ callbackInvites, callbackQRCode}) {

  return (
    <>
        <Divider/>
        <List>
            {
                callbackInvites ? (
                    <ListItem disablePadding>
                        <ListItemButton onClick={() => callbackInvites()}>
                        <ListItemIcon>
                            <GroupAddOutlinedIcon />
                        </ListItemIcon>
                        <ListItemText primary="Convidados" />
                        </ListItemButton>
                    </ListItem>
                ) : null
            }
          <ListItem disablePadding>
            <ListItemButton onClick={() => callbackQRCode()}>
              <ListItemIcon>
                <QrCodeOutlinedIcon />
              </ListItemIcon>
              <ListItemText primary="QR Code" />
            </ListItemButton>
          </ListItem>
        </List>
    </>
  );
}

export default VipListUserCardActions;

import moment from 'moment';

export function getDataDescription(_date) {
    function isSameDate(date1, date2) {
        return date1.toDateString() === date2.toDateString();
    }
      
    function isSameWeek(date1, date2) {
        const diff = Math.abs(date1 - date2);
        return diff < 7 * 24 * 60 * 60 * 1000; // 7 dias em milissegundos
    }
        
    let date = new Date(_date)
    const today = new Date();
    const tomorrow = new Date(today);
    tomorrow.setDate(today.getDate() + 1);

    // Verifica se é hoje
    if (isSameDate(date, today)) {
        return "Hoje";
    }

    // Verifica se é amanhã
    if (isSameDate(date, tomorrow)) {
        return "Amanhã";
    }

    // Verifica se está dentro da mesma semana da data atual
    if (isSameWeek(date, today)) {
        const weekdays = ["Domingo", "Segunda", "Terça", "Quarta", "Quinta", "Sexta", "Sábado"];
        const dayOfWeek = weekdays[date.getDay()];
        return dayOfWeek;
    }

    // Retorna a própria data para a próxima semana
    return date.toLocaleDateString("pt-BR");
}

export function formatDate(date) {
    return moment(date).format('DD/MM/YYYY');
}

export function formatDatetime(date) {
    return moment(date).format('DD/MM/YYYY HH:mm');
}
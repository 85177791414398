import React, { useEffect, useState } from "react";

import { BrowserRouter, Routes, Route } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import ReactPixel from "react-facebook-pixel";
import ReactGA from "react-ga";
import { ToastContainer } from "react-toastify";

import Navigation from "./components/navbar/Navbar";
import Footer from "./components/footer/Footer";
import Home from "./pages/home/Home";
import Login from "./pages/login/Login";
import Signup from "./pages/signup/Signup";
import Checkout from "./pages/checkout/Checkout";
import EventDetail from "./pages/event/EventDetail";
import Profile from "./pages/user/profile/Profile";
import Events from "./pages/user/ticket/Events";
import Tickets from "./pages/user/ticket/Tickets";
import RecoverPassword from "./pages/recover-password/RecoverPassword";
import PrivacyTerms from "./pages/terms/PrivacyTerms";
import ImageTerms from "./pages/terms/ImageTerms";
import PurchaseTerms from "./pages/terms/PurchaseTerms";
import { ThemeProvider } from "@mui/material";
import theme from "./resource/style/theme";
import ModalDialog from "./components/modal/ModalDialog";
import { open } from "./store/root/dialog/state-dialog.reducer";
import "react-toastify/dist/ReactToastify.css";
import "./App.css";

import VipLists from "./pages/viplists/VipLists";
import VipListDetail from "./pages/viplists/VipListDetail";
import UserVipLists from "./pages/user/viplist/VipLists";
import VipListInvitations from "./pages/user/viplist/VipListInvitations";
import Loading from "./components/loading/Loading";

function App() {
  const dispatch = useDispatch();
  const state = useSelector(({ auth }) => auth.state);
  const user = useSelector(({ auth }) => auth.user);

  const loading = useSelector(({ root }) => root.pageLoading.loading);

  const [didMount, setDidMount] = useState(false);

  const options = {
    autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
    debug: true, // enable logs
  };

  useEffect(() => {
    setDidMount(true);
  }, []);

  useEffect(() => {
    if (didMount) {
      if (state === null) {
        handleOpen();
      }
    }
  }, [didMount, state]);

  const handleOpen = () => {
    dispatch(open());
  };

  useEffect(() => {
    ReactPixel.init("539474247766092", options);
    ReactGA.initialize("UA-132323077-2");
  }, []);

  return (
    <>
      <ThemeProvider theme={theme}>
        <div className="main">
          <BrowserRouter>
            {loading && <Loading minHeight={800} />}
            <Navigation />
            <Routes>
              {user ? (
                <>
                  <Route path="/" element={<Home />} />
                  <Route path="/login" element={<Login />} />
                  <Route path="/signup" element={<Signup />} />
                  <Route path="/lista-vip" element={<VipLists />} />
                  <Route
                    path="/lista-vip/:listaId"
                    element={<VipListDetail />}
                  />
                  <Route path="/event/:eventId" element={<EventDetail />} />
                  <Route
                    path="/event/:eventId/checkout"
                    element={<Checkout />}
                  />
                  <Route path="/account" element={<Profile />} />
                  <Route path="/my-events" element={<Events />} />
                  <Route
                    path="/my-events/:eventId/tickets"
                    element={<Tickets />}
                  />
                  <Route path="/my-lists" element={<UserVipLists />} />
                  <Route
                    path="/my-lists/:listaId/invitations"
                    element={<VipListInvitations />}
                  />
                  <Route
                    path="/termos-de-privacidade"
                    element={<PrivacyTerms />}
                  />
                  <Route path="/termos-de-compra" element={<PurchaseTerms />} />
                  <Route path="/termos-de-imagem" element={<ImageTerms />} />
                </>
              ) : (
                <>
                  <Route path="/" element={<Home />} />
                  <Route path="/lista-vip" element={<VipLists />} />
                  <Route
                    path="/lista-vip/:listaId"
                    element={<VipListDetail />}
                  />
                  <Route path="/event/:eventId" element={<EventDetail />} />
                  <Route path="/login" element={<Login />} />
                  <Route path="/signup" element={<Signup />} />
                  <Route
                    path="/recover-password"
                    element={<RecoverPassword />}
                  />
                  <Route
                    path="/termos-de-privacidade"
                    element={<PrivacyTerms />}
                  />
                  <Route path="/termos-de-compra" element={<PurchaseTerms />} />
                  <Route path="/termos-de-imagem" element={<ImageTerms />} />
                </>
              )}
              <Route
                path="*"
                element={
                  <main style={{ flex: 1, padding: "1rem" }}>
                    <p>Página inválida</p>
                  </main>
                }
              />
            </Routes>
          </BrowserRouter>
        </div>
        <Footer />
        <ToastContainer />
        <ModalDialog />
      </ThemeProvider>
    </>
  );
}

export default App;

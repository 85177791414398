import React from 'react';
import { Container } from 'react-bootstrap';

function PurchaseTerms() {
  return (
    <div className="home-container">
      <Container>
        <div className="my-5">
          <ul>
            <h4 className="my-5"><strong>POLÍTICA DE VENDA</strong></h4>
            <li>
              <p><span style={{ fontWeight: 400 }}>O Makemynight é uma plataforma tecnológica parceira do Grupo Austin e que oferece diversos serviços como gerenciamento de listas, cobertura fotográfica, cupons de desconto, promoção de eventos e sua respectiva venda de ingressos. Os organizadores dos eventos utilizam a nossa plataforma para ofertar seus eventos ao público. Dessa forma, a o Makemynight não é responsável, em nenhum nível e em nenhuma de suas funcionalidades do app ou site, pela produção, organização, política de vendas, precificação, meia-entrada, atrações, alterações de datas e local de realização do evento e demais questões definidas, única e exclusivamente, pelo organizador do evento;</span></p>
            </li>
            <li>
              <p><span style={{ fontWeight: 400 }}> A responsabilidade do Makemynight limita-se estritamente ao uso e manutenção da tecnologia em si.</span></p>
            </li>
            <li>
              <p><span style={{ fontWeight: 400 }}>O organizador do evento é exclusivamente responsável por suas atividades estarem em conformidade com todo o arcabouço legislativo aplicável a seu evento, incluindo, mas não se limitando a, obtenção de (i) alvará de autorização para realização do evento, (ii) licença de funcionamento, divulgação e cumprimento de (i) protocolos locais e nacionais de prevenção da Covid-19 ou (ii) outras políticas sanitárias estabelecidas no local e na data de execução dos eventos;</span></p>
            </li>
            <li>
              <p><span style={{ fontWeight: 400 }}>O Makemynight, no que tange a venda de ingressos, reserva-se o direito de cancelar as compras e reembolsar todos os compradores caso identifique qualquer irregularidade na organização ou execução dos eventos não solucionadas por seus respectivos organizadores;</span></p>
            </li>
            <li>
              <p><span style={{ fontWeight: 400 }}>Compras suspeitas ou com evidências de fraude de qualquer natureza no processo de compra serão canceladas e reembolsadas;</span></p>
            </li>
            <li>
              <p><span style={{ fontWeight: 400 }}>Para acessar o evento é obrigatória a apresentação do ingresso em formato digital, na plataforma do Makemynight, através do App ou Site, ou email recebido com QR Code , juntamente com o respectivo documento oficial com foto;</span></p>
            </li>
            <li>
              <p><span style={{ fontWeight: 400 }}>O não comparecimento ao evento invalidará o ingresso e não permitirá reembolso;</span></p>
            </li>
            <li>
              <p>
                <span style={{ fontWeight: 400 }}>
                  Solicitações de estorno/cancelamento em razão do exercício do direito de arrependimento deverão ser efetuadas em até 7 dias contados da data de compra do ingresso, desde que seja realizada com antecedência mínima de 48 (quarenta e oito) horas antes da realização do evento.;
                </span>
              </p>
              <ul>
                <li>
                  <p>
                    <span style={{ fontWeight: 100 }}>
                      Nos termos da Lei Nº 14.046, de 24 de agosto de 2020 e suas alterações, na hipótese de adiamento ou de cancelamento do evento, em decorrência da pandemia da Covid-19, e, exclusivamente na situação em que o organizador do evento optar pelo reembolso
                    </span>
                  </p>
                </li>
              </ul>
            </li>
            <p><span style={{ fontWeight: 400 }}>Caso não conste da descrição do evento acima quaisquer informações adicionais sobre o evento, como estacionamento, cardápio, line-up, ordem de entrada de artistas e demais,relacionadas exclusivamente à organização do evento, deverão ser solicitadas diretamente para o organizador do evento, através do e-mail de contato;</span></p>
            <li>
              <p><span style={{ fontWeight: 400 }}>O Makemynight não recomenda a venda de tickets para eventos irregulares, que não estejam seguindo todas as orientações e protocolos de segurança recomendados pelas autoridades governamentais competentes sobre a Covid-19. Consideramos, antes de tudo, a saúde e segurança de todos;</span></p>
            </li>
            <li>
              <p><span style={{ fontWeight: 400 }}>Precisa de ajuda? Você pode enviar um email diretamente com o organizador do evento ou contato@makemynight.com.br. Nosso contato pelo instagram é o @makemynight e você pode enviar um direct por lá também.</span></p>
            </li>
          </ul>
        </div>
      </Container>
    </div>
  );
}

export default PurchaseTerms;

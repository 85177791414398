import { createSlice } from "@reduxjs/toolkit";

export const pageLoading = createSlice({
  name: "pageLoading",
  initialState: {
    loading: false,
  },
  reducers: {
    setPageLoading: (state, action) => ({
      loading: true,
    }),
    dismissPageLoading: (state, action) => ({
        loading: false,
    }),
  },
});

export const { setPageLoading, dismissPageLoading } = pageLoading.actions;
export default pageLoading.reducer;
